.modal-overlay {
    position: fixed;
    z-index: 99999;
    top: -5000px;
    right: -5000px;
    bottom: -5000px;
    left: -5000px;
    display: block;
    background: rgba(43, 46, 56, 0.5);
  }

  .modal-wrapper {
    position: fixed;
    z-index: 100000;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    overflow: auto;
    text-align: center;
    -webkit-overflow-scrolling: touch;
    padding: 0;
    margin: 0;
  }

  .modal {
    position: relative;
    text-size-adjust: 100%;
    max-width: 100%;
    padding: 30px;
    color: #2b2e38;
    background: #fff;
    display: inline-block;
    top: 0px;
    box-shadow: rgba(60, 64, 67, 0.15) 0px 0px 10px 10px;

    width: 700px;

    &.large {
      width: 992px;
    }
    &.medium {
      width: 560px;
    }
    &.narrow {
      width: 450px;
    }

    .modal-close {
      cursor: pointer;
      position: absolute;
      right: 5px;
      top: 5px;
      opacity: 0.5;
      i {
        font-size: 24px;
        color: $dark;
      }
      &:hover {
        opacity: 1;
      }
    }

    p {
      margin: 0 0 20px 0;
    }
    h1 {
      font-family: 'Roboto', sans-serif;
      font-weight: 500;
      margin: 0 0 30px 0;
    }
    h2 {
      font-family: 'Roboto', sans-serif;
      font-weight: 500;
      font-size: 24px;
      margin: 0 0 30px 0;
    }
    h3 {
      font-size: 15px;
      font-family: 'Roboto', sans-serif;
      font-weight: 400;
      text-transform: none;
      margin: 0 0 8px 0;
    }

    video {
      width: 100%;
      border: solid 1px rgba($dark, 0.5);
    }
  }

  @include media-breakpoint-down(xs) {
    .modal {
      margin-top: 0px;
      margin-bottom: 0px;
    }
  }