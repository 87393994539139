.start-page, .article, article {
  p {
    font-weight: 300;
    font-size: 19px;
    line-height: 1.5em;
    margin: 0;
    &.text-small {
      font-size: 16px;
    }
  }
  h1, .h1 {
    font-style: normal;
    font-weight: normal;
    font-size: 30px;
    line-height: 1.5em;
    margin: 0 0 0.2em 0;
  }
  h2, .h2 {
    font-family: Roboto;
    font-style: normal;
    font-weight: 300;
    font-size: 28px;
    line-height: 1.2em;
    margin: 0 0 0.5em 0;
  }
  h3, .h3 {
    margin: 0em 0 0.5em 0;
  }
  h4, .h4 {
    margin: 0em 0 0.5em 0;
  }
  h5, .h5 {
    margin: 0em 0 0.5em 0;
  }
  @media (min-width: 576px) {
    p {
      font-size: 20px;
      &.text-small {
        font-size: 18px;
      }
    }
    h1, .h1 {
      font-size: 36px;
    }
    h2, .h2 {
      font-size: 32px;
    }
  }
  @media (min-width: 959px) {
    p {
      font-size: 24px;
    }
    &.text-small {
      font-size: 20px;
    }
    h1, .h1 {
      font-size: 40px;
    }
    h2, .h2 {
      font-size: 40px;
    }
  }
}
