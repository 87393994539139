.browser-page {
  .doc-list-wrapper {
    .document-list {

      .icon {
        height: 1em;
        width: 1em;
        margin: 0 0.1em;
        
        > i {
          color: $blue;
          //font-size: 0.8em;
          display: block;
        }
      }
      padding: 0;
      //position: relative;
      li {
        min-height: 42px;
        line-height: 1.8em;
        font-size: 22px;
        width: 100%;
        position: relative;
        border: solid 1px transparent;

        > div {
          padding: 0 0 0 100px;
          position: relative;
        }
        @media only screen and (max-device-width: $max-width-tablet) {
          > div {
            padding: 0 0 0 30px;
          }
        }
        @media only screen and (max-device-width: $max-width-phone) {
          > div {
            padding: 0 0 0 10px;
          }
        }
        
        a, label {
          cursor: pointer;
          //line-height: 24px;
          font-size: 1.2rem;
          //font-family: 'Roboto';
          font-weight: 400;
          display: flex;
          &.deleted {
            font-style: italic;
            opacity: 0.8;
          }
          > i {
            font-size: 16px;
            width: 24px;
            height: 40px;
            line-height: 40px;
            margin-right: 5px;
            text-align: center; // Applies to material icons
            color: $dark;
            opacity: 0.6;
            &::before {
              background-size: 120%;
            }
            
          }

          span.flags {
            position: absolute;
            margin-top: 0;
            font-size: 0.5em;
            line-height: 0.5em;
            > span {
              margin-left: 0.5em;
              display: inline-block;
              padding: 6px;
              background: $red;
              border-radius: 4px;
              color: #FFF;
            }
            span.invite {
              background: $green;
            }
            span.demo {
              background: $red;
            }
            span.new {
              background: $green;
            }
            span.musixmatch {
              height: 17px;
              width: 17px;
              //background: url("#{$public-path}/icons/musixmatch-icon.svg");
              background-repeat: no-repeat;
              background-position: center;
              background-size: 28px;
              border-radius: 0px;
            }
          }
        }



        &.doctype-list {
          a, label {
            i {
              font-size: 20px; // Icon for lists
            }
          }
        }

        /* Number of docs in folder/list */
        span > em {
          font-size: 0.7em;
          font-style: normal;
          font-weight: 300;
        }

        /* Document in a folder */
        ul {
          padding-left: 30px;
          li {
            > div {
              padding: 0 0 0 0; 
            }
          }
        }
        @media only screen and (max-device-width: $max-width-phone) {
          ul {
            padding-left: 20px;
          }
        }
        

        .document-options {
          position: absolute;
          right: 0px;
          top: 0;
          min-width: 40px;
          align-items: center;
          display: block;
          height: 100%;
          z-index: 2;
          justify-content: flex-end;
          > div {
            width: 40px;
          }
          .dropdown {
            height: 40px;
            > i {
              font-size: 14px;
              line-height: 40px;
              cursor: pointer;
              text-align: center;
              width: 40px;
            }
          }
          > button {
            //margin-left: 10px;
            margin-right: 10px;
          }
        }
        
      }

      li:not(.doctype-folder):hover, li.drag-over {
        background-color: rgba($blue, 0.01);
        border: solid 1px rgba($blue, 0.2);
        
      }
      li:hover, li.drag-over {
        > div > .document-options {
          display: block;
        }
      }


      .folder-drag-over {
        background-color: $blue;
        color: #FFFFFF;
        label, i, span {
          color: #FFFFFF;
        }
        li {
          transform: none !important;
        }
      }

      .doctype-folder {
        .folder-icon {
          position: relative;
          &::before {
            font-family: 'Material Icons Outlined';
            content: "expand_less";
            position: absolute;
            margin-left: -30px;
            font-size: 20px;
          }
        }
        &.open {
          .folder-icon {
            &::before {
              content: "expand_more";
            }
          }
        }
      }

      .song-copy-clone ~ li {
        transform: none !important;
      }
      .song-copy-clone ~ [data-rbd-placeholder-context-id] {
        display: none !important;
      }
    }
    

    .no-songs {
      padding-left: 100px;
      span {
        font-style: italic;
        font-weight: $light;
        font-size: 18px;
        margin-bottom: 20px;
        display: block;
      }
      button {
        display: block;
        margin: 10px auto auto auto;
      }
    }
  }
}

.no-touch {
  li {
    .document-options {
      display: none;
    }
    .document-options.open {
      display: none;
    }
  }
  li:hover {
    .document-options {
      display: block;
    }
  }
}

.dnd-clone {
  label, a, i, ul, li {
    opacity: 0;
  }
}
.dnd-clone ~ li {
  transform: none !important;
}
.doctype-folder [data-rdb-placeholder-context-id] {
  display: none;
} 