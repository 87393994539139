@import url('https://fonts.googleapis.com/icon?family=Material+Icons|Material+Icons+Outlined');

.material-icons, .material-icons-outlined { vertical-align: middle; }

.material-icons.md-14, .material-icons-outlined.md-14 { font-size: 14px !important; }
.material-icons.md-15, .material-icons-outlined.md-15 { font-size: 15px !important; }
.material-icons.md-16, .material-icons-outlined.md-16 { font-size: 16px !important; }
.material-icons.md-17, .material-icons-outlined.md-17 { font-size: 17px !important; }
.material-icons.md-18, .material-icons-outlined.md-18 { font-size: 18px !important; }
.material-icons.md-22, .material-icons-outlined.md-22 { font-size: 22px !important; }
.material-icons.md-24, .material-icons-outlined.md-24 { font-size: 24px !important; }
.material-icons.md-26, .material-icons-outlined.md-26 { font-size: 26px !important; }
.material-icons.md-36, .material-icons-outlined.md-36 { font-size: 36px !important; }
.material-icons.md-48, .material-icons-outlined.md-48 { font-size: 48px !important; }

.material-icons.md-dark { color: $dark; }
.material-icons.md-dark.md-inactive { color: rgba($dark, 0.3); }

.material-icons.md-blue { color: $blue; }
.material-icons.md-light.md-inactive { color: rgba($blue, 0.3); }

.material-icons.upside-down, .material-icons-outlined.upside-down { 
  -moz-transform: scale(1, -1);
  -webkit-transform: scale(1, -1);
  -o-transform: scale(1, -1);
  -ms-transform: scale(1, -1);
  transform: scale(1, -1);
}


@import "../../node_modules/@fortawesome/fontawesome-free/css/fontawesome.css";
@import "../../node_modules/@fortawesome/fontawesome-free/css/solid.css";
@import "../../node_modules/@fortawesome/fontawesome-free/css/regular.css";
@import "../../node_modules/@fortawesome/fontawesome-free/css/brands.css";

.icon-song {
  display: inline-block;
  vertical-align:center;
  height: 1em;
  width: 1em;
  line-height: 1em;
  position: relative;
  &:before {
    position: absolute;
    content: "";
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 160%;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' version='1.1' x='0px' y='0px' viewBox='0 0 100 100' enable-background='new 0 0 100 100' xml:space='preserve'%3E%3Cpath d='M67,62c0,0.6-0.4,1-1,1H38c-0.6,0-1-0.4-1-1s0.4-1,1-1h28C66.6,61,67,61.4,67,62z M66,53H38c-0.6,0-1,0.4-1,1s0.4,1,1,1h28 c0.6,0,1-0.4,1-1S66.6,53,66,53z M67,46c0-0.6-0.4-1-1-1H48v2h18C66.6,47,67,46.6,67,46z M67,38c0-0.6-0.4-1-1-1H48v2h18 C66.6,39,67,38.6,67,38z M75,23H48v4h25v46H31V50h-4v25c0,1.1,0.9,2,2,2h46c1.1,0,2-0.9,2-2V25C77,23.9,76.1,23,75,23z M28,28.5 l13.6-3.4v8.5c-0.9-0.6-2-1-3.3-1c-2.9,0-5.3,2-5.3,4.5s2.4,4.5,5.3,4.5c2.9,0,5.3-2,5.3-4.5V18c0-0.671-0.492-1-1.2-1l-15.6,3.9 c-0.4,0.1-0.8,0.5-0.8,1v15.5c-0.9-0.6-2-1-3.3-1c-2.9,0-5.3,2-5.3,4.5s2.4,4.5,5.3,4.5c2.9,0,5.3-2,5.3-4.5V28.5z'/%3E%3C/svg%3E");
  }
  
}
.icon-song.white {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' version='1.1' x='0px' y='0px' viewBox='0 0 100 100' enable-background='new 0 0 100 100' xml:space='preserve'%3E%3Cpath d='M67,62c0,0.6-0.4,1-1,1H38c-0.6,0-1-0.4-1-1s0.4-1,1-1h28C66.6,61,67,61.4,67,62z M66,53H38c-0.6,0-1,0.4-1,1s0.4,1,1,1h28 c0.6,0,1-0.4,1-1S66.6,53,66,53z M67,46c0-0.6-0.4-1-1-1H48v2h18C66.6,47,67,46.6,67,46z M67,38c0-0.6-0.4-1-1-1H48v2h18 C66.6,39,67,38.6,67,38z M75,23H48v4h25v46H31V50h-4v25c0,1.1,0.9,2,2,2h46c1.1,0,2-0.9,2-2V25C77,23.9,76.1,23,75,23z M28,28.5 l13.6-3.4v8.5c-0.9-0.6-2-1-3.3-1c-2.9,0-5.3,2-5.3,4.5s2.4,4.5,5.3,4.5c2.9,0,5.3-2,5.3-4.5V18c0-0.671-0.492-1-1.2-1l-15.6,3.9 c-0.4,0.1-0.8,0.5-0.8,1v15.5c-0.9-0.6-2-1-3.3-1c-2.9,0-5.3,2-5.3,4.5s2.4,4.5,5.3,4.5c2.9,0,5.3-2,5.3-4.5V28.5z'/%3E%3C/svg%3E");
}

.icon-plus {
  position: relative;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-feature-settings: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-size: 16px;
  white-space: nowrap;
  text-align: center;
  color: #7799e3;
  line-height: 1em;

  &::before {
    content: " ";
    display: block;
    height: 1em;
    width: 1em;

    background-position: center bottom;
    background-size: 60%;
    background-repeat: no-repeat;
    background-image: url("data:image/svg+xml,%3Csvg width='33' height='33' viewBox='0 0 33 33' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M0 16.5C0 17.8807 1.11929 19 2.5 19H13C13.5523 19 14 19.4477 14 20V30.5C14 31.8807 15.1193 33 16.5 33C17.8807 33 19 31.8807 19 30.5V20C19 19.4477 19.4477 19 20 19H30.5C31.8807 19 33 17.8807 33 16.5C33 15.1193 31.8807 14 30.5 14H20C19.4477 14 19 13.5523 19 13V2.5C19 1.11929 17.8807 0 16.5 0C15.1193 0 14 1.11929 14 2.5V13C14 13.5523 13.5523 14 13 14H2.5C1.11929 14 0 15.1193 0 16.5Z' fill='%231A1A1A'/%3E%3C/svg%3E%0A");
  }

  &.blue:before {
    background-image: url("data:image/svg+xml,%3Csvg width='33' height='33' viewBox='0 0 33 33' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M0 16.5C0 17.8807 1.11929 19 2.5 19H13C13.5523 19 14 19.4477 14 20V30.5C14 31.8807 15.1193 33 16.5 33C17.8807 33 19 31.8807 19 30.5V20C19 19.4477 19.4477 19 20 19H30.5C31.8807 19 33 17.8807 33 16.5C33 15.1193 31.8807 14 30.5 14H20C19.4477 14 19 13.5523 19 13V2.5C19 1.11929 17.8807 0 16.5 0C15.1193 0 14 1.11929 14 2.5V13C14 13.5523 13.5523 14 13 14H2.5C1.11929 14 0 15.1193 0 16.5Z' fill='%237799e3'/%3E%3C/svg%3E%0A");
  }
  &.white:before {
    background-image: url("data:image/svg+xml,%3Csvg width='33' height='33' viewBox='0 0 33 33' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M0 16.5C0 17.8807 1.11929 19 2.5 19H13C13.5523 19 14 19.4477 14 20V30.5C14 31.8807 15.1193 33 16.5 33C17.8807 33 19 31.8807 19 30.5V20C19 19.4477 19.4477 19 20 19H30.5C31.8807 19 33 17.8807 33 16.5C33 15.1193 31.8807 14 30.5 14H20C19.4477 14 19 13.5523 19 13V2.5C19 1.11929 17.8807 0 16.5 0C15.1193 0 14 1.11929 14 2.5V13C14 13.5523 13.5523 14 13 14H2.5C1.11929 14 0 15.1193 0 16.5Z' fill='%23FFFFFF'/%3E%3C/svg%3E%0A");
  }
}