.wizard {
  h2 {
    font-size: 30px;
    font-weight: 400;
    margin: 0;
  }
  h3 {
    font-size: 20px;
    font-weight: 400;
    margin: 0;
  }
  h4 {
    margin: 0;
    font-weight: 500;
    font-size: 18px;
  }
  p {
    margin: 0 0 10px 0;
    font-size: 18px;
    line-height: 24px;
  }

  .new-song-language {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
    position: relative;
    
    .language-button {
      margin: auto;
      border: 1px solid rgba($blue, 0.2);
      display: flex;
      justify-content: center;
      border-radius: 5px;
      div {
        padding: 10px 15px;
        height: 40px;
        cursor: pointer;
        &:first-child {
          border-right: 1px solid rgba($blue, 0.2);
        }
        span {
          display: block;
          line-height: 20px;
          font-family: 'Roboto Condensed', sans-serif;
          font-size: 16px;
        }
        &.selected {
          background-color: $blue;
          span {
            color: #FFFFFF;
          }
        }
      }
    }

  }



  .topics {
    display: flex;
    gap: 15px;
    flex-wrap: wrap;
    justify-content: center;
    div {
      margin-bottom: 0;
      border: 1px solid $blue;
      border-radius: 5px;
      white-space: nowrap;
      line-height: 40px;
      font-size: 18px;
      padding: 0 15px;
      cursor: pointer;
      transition: all 200ms;
      font-weight: 400;
      --webkit-user-select: none;
      user-select: none;
      background-color: $lightblue;
    }
    div:hover, .active {
      background-color: $blue;
      color: $white;
    }
  }
  button {
    height: 50px;
    line-height: 50px;
    padding: 0 20px;
    font-size: 16px;
  }

  .emoji {
    font-size: 26px;
  }
  .form-group {
    label {
      line-height: 30px;
      font-weight: 400;
      font-size: 16px;
    }
  }
  textarea {
    font-size: 17px;
    line-height: 26px;
    height: 90px;
  }

  .back {
    cursor: pointer;
    font-size: 14px;
    display: block;
  }

  &.creating {
    h1, h2, h3, h4, div, span, button, textarea, .back {
      opacity: 0.5;
      cursor: default;
    }
  }
}