.modal-create-new {
  h1 {
    font-size: 20px;
    line-height: 1.5em;
    margin-bottom: 20px;
  }
  p {
    margin: 0;
    padding: 0;
  }
  h2 {
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    margin: 0 0 15px 0;
    font-family: 'Roboto Condensed', sans-serif;
    font-weight: 400;
  }
  @media only screen and (max-device-width: $max-width-phone) {
    h2 {
      font-size: 0.9em;
      margin-bottom: 1em;
    }
  }
  input {
    height: 40px;
    width: 100%;
    max-width: 500px;
    border: 0 none;
    border-bottom: rgba(0, 0, 0, .16) solid 1px;
    text-align: left;
    font-size: 22px;
    line-height: 40px;
    text-align: center;
    font-family: 'Roboto Condensed', sans-serif;
    font-weight: 400;
    margin: 0 auto 20px auto;
  }
  
  $new-song-sheet-width: (794px * 0.3);
  $new-song-sheet-height: (671px * 0.3);

  .new-song-type {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
    
    > div {
      width: 50%;
      padding: 10px 15px 0 15px;
      text-align: left;
      cursor: pointer;
      border: 1px transparent solid;
      opacity: 0.5;
      &.selected {
        border: 1px $blue solid;
        box-shadow: $blue 0px 1px 3px 1px;
        opacity: 1;
      }

      h3 {
        font-size: 1.6em;
        padding: 0;
        margin: 0;
      }
      > div {
        position: relative;
        width: 100%;
        background-size: contain;
        background-repeat: no-repeat;
        margin: 0 auto;
        height: 135px;
      }
      

      
    }

    @media only screen and (max-device-width: $max-width-phone) {
      > div {
        padding: 10px 10px 0 10px;
        h3 {
          font-size: 1em;
          font-weight: 500;
        }
        > div {
          height: 80px;
        }
      }
    }
    
    
    > div:first-child {
      justify-content: flex-end;
      > div {
        background-position: right;
        background-image: url("#{$public-path}/static/images/type-lyrics.png");
        
      }
    }
    > div:last-child {
      justify-content: flex-start;
      > div {
        background-position: left;
        background-image: url("#{$public-path}/static/images/type-chords.png");
      }
    }
    
  }

  .new-song-language-holder, .new-song-button-holder {
    margin-top: 30px;
  }

  .new-song-language {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
    position: relative;
    
    .language-button {
      margin: auto;
      border: 1px solid rgba($blue, 0.2);
      display: flex;
      justify-content: center;
      border-radius: 5px;
      div {
        padding: 10px 15px;
        height: 40px;
        cursor: pointer;
        &:first-child {
          border-right: 1px solid rgba($blue, 0.2);
        }
        span {
          display: block;
          line-height: 20px;
          font-family: 'Roboto Condensed', sans-serif;
          font-size: 16px;
        }
        &.selected {
          background-color: $blue;
          span {
            color: #FFFFFF;
          }
        }
      }
    }

  }

  .new-song-copy-of {
    margin-bottom: 20px;
    span {
      font-weight: $semibold;
    }
  }

  button {
    line-height: 30px;
    height: 50px;
    padding: 10px;
    font-size: 16px;
  }
}