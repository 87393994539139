.article-page {
    font-size: $baseFontSize;
    background: $bgGray;
    height: calc(100vh);
    overflow: auto;
    text-align: left;
    .article-container {
        padding: 0;
        margin: 15px auto -15px auto;
        max-width: $editorMaxWidth;
    }
    article, .article {
        background: #ffffff;
        padding: 30px 30px 30px 30px;
        margin: 30px auto 30px auto;
        max-width: $editorMaxWidth;
        text-align: left;
        box-shadow: rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
        p, ul, ol {
            margin: 0.8em 0 0.5em 0
        }
        ul, ol {
            li {
                list-style-type: disc;
            }
        }
    }
}