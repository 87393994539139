#wordbrowser-root {
  width:0;
  height:0;
}
.word-browser {
  opacity: 0.95;
  background-color: #ffffff;
  box-shadow: rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
  max-height: calc(100vh - 70px);
  overflow: auto;
  border: 0 none;
  border-radius: 5px;
  border: 1px solid rgba($blue, 0.2);
  display: inline-block;
  width: auto;
  height: auto;
  padding: 0 10px 2px 10px;
  text-align: left;
  z-index: 300;
  position: absolute;
  font-family: 'Quicksand', sans-serif;
  color: #1A1A1A;

  //opacity: 1;
	//animation-name: fadeIn;
	//animation-iteration-count: 1;
	//animation-duration: 200ms;

  &.centered {
    left: 50%;
    transform: translateX(-50%);
  }

  .word-close {
    display: none;
    right: 10px;
    top: 10px;
    position: absolute;
    border-radius: 2px;
    width: 20px;
    height: 20px;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    cursor: pointer;
    color: #AAAAAA;
    &:hover {
      background-color: rgba($blue, 0.2);
      color: #000000;
    }
  }

  > .dropdown {
    display: none;
    //position: absolute;
    //right: 40px;
    //top: 10px;
    max-width: 100px;
    > label {
      height: 30px;
      line-height: 30px;
    }
  }
  .word-label {
    font-size: 17px;
    line-height: 20px;
    white-space: nowrap;
    cursor: pointer;
    letter-spacing: 0.08em;
    display: block;
    /*
    input {
      display: none;
      height: 25px;
      font-size: 0.8em;
      border: 0 none;
      border-bottom: rgba(0, 0, 0, .16) solid 1px;
      text-align: left;
      font-size: 16px;
      line-height: 25px;;

      font-family: 'Roboto', sans-serif; // 'BravuraText', sans-serif;
      font-weight: 400;
      width: 200px;
    }
    */
    i {
      display: inline-block;
      font-size: 14px;
      line-height: 20px;
    }
    > span {
      display: inline-block;
      font-family: 'Roboto', sans-serif;
      font-weight: 300;
      font-size: 11px;
      padding-left: 10px;
      color: $gray;
      code {
        color: $gray;
        font-size: 11px;
        line-height: 11px;
        border-radius: 5px;
        font-family: 'Roboto', sans-serif;
        font-weight: 300;
        display: inline-block;
        padding-right: 2px;
        padding-left: 2px;
      }
    }
  }
  
  .loading {
    margin-top: 10px;
  }
  
  .no-result {
    padding: 5px 0 0 0;
    span {
      color: $gray;
      font-size: 15px;
      line-height: 36px;
    }
  }

  .word-result {
    margin-left: -6px;
    margin-right: -6px;

    h3 {
      font-family: 'Roboto', sans-serif;
      font-weight: 400;
      line-height: 16px;
      font-size: 12px;
      letter-spacing: 0.05em;
      text-transform: uppercase;
      padding: 0;
      margin: 0;
      margin-left: 6px;
      margin-right: 6px;
      margin-top: 15px;
    }

    .suggestion-description {
      margin: 5px;
      p {
        font-size: 14px;
        margin: 0;
        padding: 0;
      }
    }

    .sentences {
      > div {
        padding: 0 6px;
        line-height: 26px;
        cursor: pointer;
        span {
          font-size: 16px;
        }
        &:hover {
          //background-color: $blue;
          background-color: rgba($blue, 0.2);
          span {
            //color: #FFF;
          }
        }
        
      }
    }

    .word-list > div {
      //display: inline-block;
      display: inline-flex;
      flex-wrap: nowrap;
      padding: 0 0 0 8px;
      line-height: 26px;
      font-size: 15px;
      font-family: 'Roboto', sans-serif;
      font-weight: 300;
      //background-color: rgba($blue,.05);
      border: 1px solid rgba($blue,.2);
      margin: 6px;
      border-radius: 10px;
      cursor: pointer;

      &.show-more {
        padding-right: 8px;
      }
      
      > span, > label {
        flex-grow: 1;
        display: inline-block;
        margin: 0 2px;
        cursor: pointer;
      }

      &:hover {
        //background-color: $blue;
        background-color: rgba($blue, 0.2);
        > label {
          //color: #FFF;
        }
      }
      
      .dropdown {
        // background-color: rgba($blue,.05);
        box-shadow: none;
        border: 0 none;
        position: relative;
        height: 26px;
        width: 20px;
        padding: 0;
        margin: 0;
        label {
          position: relative;
          background-color: transparent;
          border: 0 none;
          height: 26px;
          width: 20px;
          padding: 0;
          margin: 0;
          line-height: 26px; 
          text-align: center;
          i {
            opacity: 0.5;
            font-size: 11px;
          }
        }
      }
      
    }
    
  }

  &.wb-loading {
    padding-top: 10px;
  }
  &.wb-preview {
    .word-label {
      i:before {
        color: rgba($dark, 0.5);
        font-size: 11px;
      }
      > span {
        padding-left: 5px;
      }
    }
  }
  &.wb-expanded {
    opacity: 1;
    min-width: 350px;
    padding: 10px;
    .word-close {
      display: inline-block;
    }
    .dropdown {
      display: inline-block;
    }
    .word-label {
      //input {
      //  display: inline-block;
      //}
      i {
        transform: translateX(-100%);
        font-size: 12px;
        display: none;
      }
      > span {
        display: none;
      }
    }
  }
}

.editor.align-left {
  .word-browser {
    &.centered {
      left: 90px;
      right: 90px;
      transform: none;
    }
  }
}
