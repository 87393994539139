.login-form {
    padding-top: 20px;
    padding-bottom: 22px;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    h1 {
      margin: 0 0 0.5em 0;
    }
    p {
      margin: 0 0 1em 0;
    }
    button, .button {
      width: 250px;
      height: 40px;
      line-height: 40px;
      margin-bottom: 10px;
      font-family: 'Roboto', sans-serif;
      font-weight: 400;
      span {
        height: 40px;
        line-height: 40px;
        font-weight: 400;
      }
    }
    input {
      width: 250px;
      height: 40px;
      line-height: 40px;
      margin-bottom: 10px;
    }
    .google-login-button {
      height: 40px;
      background: rgba(#ffffff, 0.2);
      color: $dark;
      border-width: 0;
      border-radius: 5px;
      white-space: nowrap;
      //box-shadow: 1px 1px 0px 1px rgba(0, 0, 0, 0.05);
      transition-property: background-color, box-shadow;
      transition-duration: 150ms;
      transition-timing-function: ease-in-out;
      padding: 0;
      position: relative;
      margin-bottom: 10px;
      &:before {
        content: "";
        height: 40px;
        width: 40px;
        display: block;
        position: absolute;
        left: 0;
        background-size: 20px;
        background-repeat: no-repeat;
        background-position: center;
        background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' width='18px' height='18px' viewBox='0 0 48 48' class='abcRioButtonSvg'%3E%3Cg%3E%3Cpath fill='%23EA4335' d='M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z'%3E%3C/path%3E%3Cpath fill='%234285F4' d='M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z'%3E%3C/path%3E%3Cpath fill='%23FBBC05' d='M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z'%3E%3C/path%3E%3Cpath fill='%2334A853' d='M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z'%3E%3C/path%3E%3Cpath fill='none' d='M0 0h48v48H0z'%3E%3C/path%3E%3C/g%3E%3C/svg%3E");
      }
      &:focus,
      &:hover {
        box-shadow: 1px 4px 5px 1px rgba(0, 0, 0, 0.1);
        outline: none;
      }

      &:active {
        background-color: #e5e5e5;
        box-shadow: none;
        outline: none;
        transition-duration: 10ms;
      }
      span {
        display: inline-block;
        vertical-align: middle;
        font-size: 14px;
      }
    }
    .facebook-login-button {
      position: relative;
      // font-family: Lucida Grande, Helvetica Neue, Helvetica, Arial, sans-serif;
      font-size: 14px;
      background: #3a5a97;
      color: #fff;
      //text-shadow: 0 -1px 0 rgba(0, 0, 20, .4);
      text-decoration: none;
      position: relative;
      text-align: center;
      white-space: nowrap;
      transition-property: background-color, box-shadow;
      transition-duration: 150ms;
      transition-timing-function: ease-in-out;
      &:focus,
      &:hover {
        box-shadow: 1px 4px 5px 1px rgba(0, 0, 0, 0.1);
      }

      &:active {
        background-color: #e5e5e5;
        box-shadow: none;
        transition-duration: 10ms;
      }

      i {
        position: absolute;
        left: 10px;
        top: 50%;
        transform: translateY(-50%);
        margin-right: 10px;
        //transform: translateY(3px);
        width: 20px;
        height: 18px;
        margin-right: 10px;
        background: #FFF;
        padding: 0;
        color: #3a5a97;
        &::before {
          bottom: -2px;
          right: 4px;
          position: absolute;
        }
      }
    }

    .email-login {
      padding-top: 10px;
      > div {
        padding-top: 10px;
        // border-top: solid 0.5px rgba($blue, 0.5);
      }
      label {
        display: block;
        padding-bottom: 10px;
      }
      input {
        margin-bottom: 10px;
        border-top: 0 none;
        border-left: 0 none;
        border-right: 0 none;
      }
    }
    .email-login-button {
      position: relative;
      color: $dark;
      font-size: 14px;
      text-decoration: none;
      //border-width: 0;
      position: relative;
      text-align: center;
      white-space: nowrap;
      transition-property: background-color, box-shadow;
      transition-duration: 150ms;
      transition-timing-function: ease-in-out;
      &:focus,
      &:hover {
        box-shadow: 1px 4px 5px 1px rgba(0, 0, 0, 0.1);
      }

      &:active {
        //background-color: #e5e5e5;
        box-shadow: none;
        transition-duration: 10ms;
      }

      i {
        position: absolute;
        left: 10px;
        top: 50%;
        transform: translateY(-50%);
        margin-right: 10px;
        width: 20px;
        height:20px;
        margin-right: 10px;
        // background: #FFF;
        padding: 0;
        color: $blue;
        &::before {
          left: 4px;
          top: 5px;
          position: absolute;
        }
      }
    }
  }

  
@media (max-width: 575.98px) {
    .login-form {
        justify-content: center;
      }
}