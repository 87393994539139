.help-bubble {
    position: absolute;
    top: calc(100% + 10px);
    z-index: 1000;
    > div {
        //position: absolute;
        position: relative;
        z-index: 1001;
        //left: 0;
        //top: 0;
        margin-top: 4px;
        box-shadow: rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
        background-color: #ffffff;
        border: 0 none;
        //border-radius: 5px;

        > div {
            &:before {
                content: "";
                position: absolute;
                z-index: 35;
                width: 0; 
                height: 0; 
                border-left: 8px solid transparent;
                border-right: 8px solid transparent;
                border-bottom: 8px solid #FFFFFF;
                //box-shadow: rgba(60, 64, 67, 0.15) 0px -2px 6px 0px ;
                //box-shadow: rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
                left: 0;
                top: 0;
                transform: translateX(20px);
                margin-top: -8px;
            }

            position: relative;
            z-index: 30;
            //z-index: 10;
            
            //border: 1px solid rgba($blue, 0.2);
            width: auto;
            height: auto;
            min-width: 250px;
            line-height: 1.5em;
            padding: 15px;
            text-align: left;
        }
    }

    &.apos-5 > div > div:before { transform: translateX(5px); }
    &.apos-10 > div > div:before { transform: translateX(10px); }
    &.apos-15 > div > div:before { transform: translateX(15px); }
    &.apos-20 > div > div:before { transform: translateX(20px); }
    &.apos-25 > div > div:before { transform: translateX(25px); }
    &.apos-30 > div > div:before { transform: translateX(30px); }

    &.apos-middle > div > div:before { left: 50%; transform: translateX(-10px); }

    &.apos-right-5 > div > div:before { left: auto; right: 0; transform: translateX(-5px); }
    &.apos-right-10 > div > div:before { left: auto; right: 0; transform: translateX(-10px); }
    &.apos-right-15 > div > div:before { left: auto; right: 0; transform: translateX(-15px); }
    &.apos-right-20 > div > div:before { left: auto; right: 0; transform: translateX(-20px); }
    &.apos-right-25 > div > div:before { left: auto; right: 0; transform: translateX(-25px); }
    &.apos-right-30 > div > div:before { left: auto; right: 0; transform: translateX(-30px); }
    
    label, p, span {
        padding: 0;
        margin: 0;
    }
    label {
        display: block;
        //font-weight: $semibold;
        font-family: 'Roboto Condensed', sans-serif;
        font-size: 16px;
        line-height: 1.5em;
    }
    p {
        font-size: 15px;
        line-height: 1.4em;
        font-family: 'Quicksand', sans-serif;
    }
    video {
        display: block;
        height: auto;
        width: 250px;
        margin-top: 10px;
    }
}