.browser-page {
  .doc-list-wrapper {
    .welcome {
      padding: 50px 100px 50px 100px;
      width: 100%;
      h3 {
        font-size: 1rem;
        margin: 0 0 0.5em 0;
      }
      h4 {
        font-size: 0.9rem;
        margin: 0 0 0.5em 0;
      }
      p {
        font-size: 0.9rem;
        margin: 0 0 1em 0;
        a {
          font-weight: 600;
        }
      }
    }
    @media only screen and (max-device-width: $max-width-tablet) {
      .welcome {
        padding: 30px 30px 30px 30px;
        h3 {
          font-size: 1.2rem;
        }
        h4 {
          font-size: 1rem;
          margin: 0 0 0.5em 0;
        }
        p {
          font-size: 1rem;
          margin: 0 0 1em 0;
          a {
            font-weight: 600;
          }
        }
      }
    }
  }
}    