.rehearsal-mark {
  border: solid 1px $dark;
  min-width: 1.2em;
  height: 1.2em;
  line-height: 1.2em;
  text-align: center;
  font-size: 15px;
  font-family: 'Roboto Condensed', sans-serif;  
  display: inline-block;
}
button span.rehearsal-mark {
  border-color: #AAAAAA;
  color: #AAAAAA;
}
.sm-editor-tabs > div > span span.rehearsal-mark {
  border-color: #AAAAAA;
}
.sm-editor-tabs > div > span.active span.rehearsal-mark {
  border-color: $blue;
}
.repeat-ending {
  border-top: solid 1px $dark;
  border-left: solid 1px $dark;
  text-align: left;
  padding-left: 5px;
  font-family: 'Roboto Condensed', sans-serif;
  font-size: 12px;
  display: inline-block;
}
button span.repeat-ending {
  color: $dark;
  line-height: 12px;
  padding-top: 3px;
  min-width: 30px;
  color: #AAAAAA;
  border-color: #AAAAAA;
}
.sm-editor-tabs > div > span span.repeat-ending {
  border-color: #AAAAAA;
}
.sm-editor-tabs > div > span.active span.repeat-ending {
  border-color: $blue;
}

.bars-row {
  //height: 50px;
  padding-top: 15px;
  padding-bottom: 15px;
  display: flex;
  flex-grow: 1;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: stretch;
  .dropdown {
    display: none;
    position: absolute !important;
    right: 0;
    top: -20px;
    z-index: 100;
    height: 16px;
    label {
      font-size: 11px;
      line-height: 1em;
      height: 1em;
      width: 1em;
      display: inline-block;
      cursor: pointer;
      i {
        color: $blue;
        font-size: 11px;
        line-height: 1em;
        height: 1em;
      }
    }
    > div > ul > li i.fa-square {
      position: relative;
      span {
        font-size: 9px;
        position: absolute;
        width: 100%;
        text-align: center;
        color: $blue;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        font-weight: 400;
      }
    }
  }
  i.chord {
    color: $dark;
    z-index: 2;
    font-style: normal;
    font-family: 'Roboto Condensed', sans-serif;
    font-size: 0.87rem; // 1rem;
    font-weight: normal;
    line-height: 1.6rem;
    height: 1.6rem;
    display: block;
    min-width: 1.5em;
    border: 1px solid transparent;
    padding: 0 2px;
    i {
      font-style: normal;
      position: relative;
      top: -0.5em;
      font-size: 80%;
    }
    //&.dragging {
    //  opacity: 0;
    //}
  }
  @media only screen and (max-device-width: $max-width-phone) {
    i.chord {
      font-size: 0.75rem;
    }
  }
  .bar-above {
    position: absolute;
    left: 0;
    right: 0;
    top: -1.5rem;
    height: 1.5rem;
    .rehearsal-mark {
      display: inline-block;
      position: absolute;
      left: 0;
      top: 0;
      min-width: 1.2rem;
      height: 1.2rem;
      line-height: 1.2rem;
    }
    .repeat-ending {
      position: absolute;
      left: 0;
      top: 0;
      display: inline-block;
      width: 50%;
      height: 1.2rem;
      line-height: 1.2rem;
    }
  }
  .bl-left,
  .bl-right {
    position: absolute;
    height: 100%;
    width: 9px;
    background: #FFF;
    z-index: 1;
    > span {
      display: block;
      height: 100%;
      width:0px;
    }
    //&:before {
    //  content: "";
    //  display: block;
    //  height: 100%;
    //}
    //left: -4px;
    span {
      margin-left:4px;
      border-right: solid 1px $dark;
    }
    &.none span {
      margin-left:2px;
      width: 5px;
      border-left: solid 1px transparent;
      border-right: solid 1px transparent;
    }
    &.light-light span {
      margin-left:2px;
      width: 5px;
      border-left: solid 1px $dark;
      border-right: solid 1px $dark;
    }
    &.light-heavy span {
      margin-left:2px;
      width: 5px;
      border-left: solid 1px $dark;
      border-right: solid 2px $dark;
    }
    &.heavy-light span {
      margin-left:2px;
      width: 5px;
      border-left: solid 2px $dark;
      border-right: solid 1px $dark;
    }
    &.repeat-backward span:before {
      content: ":";
      line-height: 1em;
      position: absolute;
      left: -4px;
      top: 50%;
      transform: translateY(-50%);
    }
    &.repeat-forward span:after {
      content: ":";
      line-height: 1em;
      position: absolute;
      right: -4px;
      top: 50%;
      transform: translateY(-50%);
    }
  }
  .bl-left {
    left: -5px;
  }
  .bl-right {
    right: -5px;
  }
  div:hover > .f-devide {
    display: block;
  }
  div > span {
    display: block;
  }
  .bar {
    position: relative;
    padding: 0 10px 0 10px;
    text-align: left;
    //border-right: solid 1px $dark;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: stretch;
    .bf {
      position: relative;
      flex-grow: 1;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-content: stretch;
      white-space: nowrap;
      span {
        position: relative;
        display: block;
        flex-wrap: nowrap;
        white-space: nowrap;
      }
    }
    &:hover, &.bar-focused {
      i.chord {
        border: dashed 1px $blue;
      }
      .bl-left,
      .bl-right {
        background: $blue;
      }
      .dropdown {
        display: block;
      }
    }
    .bf > .dragover .chord {
      border: dashed 1px $blue;
      background-color: $blue;
      animation-name: chordhover;
      animation-duration: 1000ms;
      animation-timing-function: ease;
      animation-iteration-count: infinite;
    }
  }

  &.elem-above {
    padding-top: 30px;
  }
}

@keyframes chordhover {
  0% {
    opacity: 0.5;
  }
  50% {
    opacity: 0.2;
  }
  100% {
    opacity: 0.5;
  }
}


.barline-editor {
  display: flex;
  justify-content: stretch;
  button {
    margin: 5px;
    display: inline-block;
    flex-grow: 1;
    &:first-child {
      margin-left: 0;
    }
    &:last-child {
      margin-right: 0;
    }
  }
}

.above-bar-editor {
  > div {
    display: flex;
    justify-content: stretch;
    button {
      margin: 5px;
      display: inline-block;
      flex-grow: 1;
      &:first-child {
        margin-left: 0;
      }
      &:last-child {
        margin-right: 0;
      }
    }
  }
}

.c-1 > div, .c-1 > span {
  display: block;
  flex-basis: 100%;
  flex-wrap: nowrap;
  white-space: nowrap;
}
.c-2 > div, .c-2 > span {
  display: block;
  flex-basis: percentage(math.div(1,2));
  flex-wrap: nowrap;
  white-space: nowrap;
}
.c-3 > div, .c-3 > span {
  display: block;
  flex-basis: percentage(math.div(1,3));
  flex-wrap: nowrap;
  white-space: nowrap;
}
.c-4 > div, .c-4 > span {
  display: block;
  flex-basis: percentage(math.div(1,4));
  flex-wrap: nowrap;
  white-space: nowrap;
}
.c-5 > div, .c-5 > span {
  display: block;
  flex-basis: percentage(math.div(1, 5));
  flex-wrap: nowrap;
  white-space: nowrap;
}
.c-6 > div, .c-6 > span {
  display: block;
  flex-basis: percentage(math.div(1, 6));
  flex-wrap: nowrap;
  white-space: nowrap;
}
.c-7 > div, .c-7 > span {
  display: block;
  flex-basis: percentage(math.div(1,7));
  flex-wrap: nowrap;
  white-space: nowrap;
}
.c-8 > div, .c-8 > span {
  display: block;
  flex-basis: percentage(math.div(1,8));
  flex-wrap: nowrap;
  white-space: nowrap;
}

.editor.edit-active {
  .bars-row {
    i.chord:hover {
      cursor: pointer;
      background-color: $blue;
      color: #ffffff;
      border-radius: 2px;
    }
    .bl-left:hover,
    .bl-right:hover {
      cursor: pointer;
      //background-color: $blue;
      //color: #ffffff;
    }
  }
}
