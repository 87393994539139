@keyframes spinningsearch {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.toolbar {
  height: 60px;
  padding: 10px 0;
  //border-bottom: #e2e2e2 solid 1px;
  box-shadow: rgba(60, 64, 67, 0.05) 0px 1px 3px 1px;
  display: flex;
  justify-content: space-between;
  font-size: 20px;
  background: #ffffff;
  position: relative;
  z-index: 100;

  @media only screen and (max-device-width: $max-width-phone) {
    box-shadow: none;
  }
  
  .tb-left {
    display: flex;
    justify-content: left;
  }
  .tb-center {
    display: flex;
    justify-content: center;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    z-index:200;
  }
  .tb-right {
    display: flex;
    justify-content: right;
  }

  .branding {
    font-size: 22px;
    line-height: 39px;
  }
  @media only screen and (max-device-width: $max-width-tablet) {
    .branding {
      display: none;
    }
  }

  .separator {
    height: 38px;
    text-align: center;
    line-height: 38px;
    margin: 0 5px;
    > span {
      border-left: #e2e2e2 solid 1px;
      border-radius: 0;
      height: 40px;
      width: 0px;
    }
  }

  .search-music {

    background: rgba($blue, 0.05);
    margin-left: 10px;
    position: relative;
    text-align: left;
    padding-left: 15px;

    @media (max-width: $max-width-phone) {
      position: absolute;
      left: 0;
      width: 100vw;
      background: #FFF;
      margin: 0;
      padding: 0 10px;
      display: none;
      &.d-block {
        display: block;
      }
      input {
        width: auto;
      }
    }

    &:before { 
      font-family: "Material Icons";
      content: "search";
      font-size: 16px;
      position: absolute;
      left: 10px;
      line-height: 40px;
      color: $dark;
      opacity: 0.6;
      display: inline-block;
    }
    &.searching:before {
      content: "";
      border-radius: 50%;
      border-top: $dark 1px solid;
      border-right: $dark 1px solid;
      border-left: $dark 1px solid;
      width: 10px;
      height: 10px;
      top: 15px;

      animation: spinningsearch 3s infinite linear;
    }
    
    input {
      border: 0 none;
      background: transparent;
      margin-left: 10px;
    }
    .search-results {
      position: absolute;
      top: 40px;
      background: #ffffff;
      box-shadow: rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
      width: 480px;
      text-align: left;
      padding: 5px 0;
      height: (((28px + 13px + 10px + 2px) * 10) + 30px);
      overflow: hidden;
      overflow-y: auto;
      @media (max-width: $max-width-phone) {
        left:0;
        width: 100vw;
      }
      label {
        padding: 5px 15px;
        font-size: 13px;
        font-weight: 300;
        line-height: 30px;
        opacity: 0.6;
        a, a:link {
          color: $dark;
        }
      }
      > div {
        padding: 5px 15px;
        border-top: solid 1px transparent;
        border-bottom: solid 1px transparent;
        cursor: pointer;
        &:hover {
          background-color: rgba($blue, 0.01);
          border-top: solid 1px rgba($blue, 0.1);
          border-bottom: solid 1px rgba($blue, 0.1);
        }
        p {
          margin: 0;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden; 
          font-size: 15px;
          font-weight: 400;
          line-height: 28px;
          &.artist-name {
            font-size: 10px;
            line-height: 13px;
            font-weight: 600;
            text-transform: uppercase;
          }
        }
        .no-result {
          p {
            font-size: 13px;
            opacity: 0.6;
          }
        }
      }
    }
  }

  .dropdown-button {
    margin: 0 5px;
  }
  .option {
    height: 38px;
    text-align: center;
    line-height: 38px;
    margin: 0 5px;
    position: relative;
    > span, > span > span, > a {
      color: #aaaaaa;
    }
    > span,
    > a,
    > li,
    > i {
      font-size: 16px;
      white-space: nowrap;
      text-align: center;
    }
    > span,
    > a {
      border: 0 none;
      border-radius: 50%;
      height: 38px;
      width: 38px;
      display: block;
      cursor: pointer;
      user-select: none;
      background-color: $lightblue;
      border: 1px solid transparent;
      
    }
    &.border {
      span,
      a {
        border: 1px solid rgba($blue, 0.2);
      }
    }
    &.with-text {
      > span,
      > a {
        width: auto;
        padding-left: 10px;
        padding-right: 10px;
        font-family: 'Roboto', sans-serif;
        font-weight: $medium;
        font-size: 14px;
        border-radius: 20px;
        > i {
          margin-right: 5px;
        }
      }
    }
    &.toggle-chords {
      span {
        font-size: 14px;
        font-family: 'Roboto', sans-serif;
        //font-family: "Roboto Condensed", sans-serif;
        //color: #aaaaaa;
        > span {
          //margin-left: -2px;
          //margin-right: -2px;
          font-style: normal;
          position: relative;
          top: -0.5em;
          font-size: 80%;
          font-family: 'Roboto', sans-serif;
      }
      }
    }
    &.plus {
      > span {
        position: relative;
        padding-left: 32px;
      }
      > span:before {
        content: "";
        display: inline-block;
        line-height: inherit;
        position: absolute;
        top: 6px;
        bottom: 0;
        left: 2px;
        width: 30px;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' viewBox='0 0 100 125' version='1.1' x='0px' y='0px'%3E%3Ctitle%3E3.5%3C/title%3E%3Cdesc%3ECreated with Sketch.%3C/desc%3E%3Cg stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg fill='%23AAAAAA'%3E%3Cpath d='M33,49.5 L33,49.5 L33,49.5 C33,50.8807119 34.1192881,52 35.5,52 L46,52 L46,52 C46.5522847,52 47,52.4477153 47,53 L47,63.5 L47,63.5 C47,64.8807119 48.1192881,66 49.5,66 L49.5,66 L49.5,66 C50.8807119,66 52,64.8807119 52,63.5 L52,53 L52,53 C52,52.4477153 52.4477153,52 53,52 L63.5,52 L63.5,52 C64.8807119,52 66,50.8807119 66,49.5 L66,49.5 L66,49.5 C66,48.1192881 64.8807119,47 63.5,47 L53,47 L53,47 C52.4477153,47 52,46.5522847 52,46 L52,35.5 L52,35.5 C52,34.1192881 50.8807119,33 49.5,33 L49.5,33 L49.5,33 C48.1192881,33 47,34.1192881 47,35.5 L47,46 L47,46 C47,46.5522847 46.5522847,47 46,47 L35.5,47 L35.5,47 C34.1192881,47 33,48.1192881 33,49.5 Z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
        background-position: left;
        background-size: 34px;
        background-repeat: no-repeat;
      }
    }

    .sub-dropdown {
      position: relative;
      > span {
        //border-radius: 50px;

        border: 0 none;
        height: 38px;
        width: 38px;
        display: block;
        cursor: pointer;
        user-select: none;
        //background-color: $lightblue;
        //border: 1px solid transparent;
        border: 1px solid rgba($blue, 0.2);

        width: auto;
        padding-left: 15px;
        padding-right: 10px;
        //font-weight: $semibold;
        font-size: 14px;
        border-radius: 5px;
        
        &:before {
          font-family: "Material Icons";
          content: "expand_more";
          font-size: 12px;
          position: relative;
          left: -7px;
        }
      }
      &.active > span:before {
        content: "expand_less";
      }
      > ul {
        position: absolute;
        z-index: 10;
        background-color: #ffffff;
        box-shadow: rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
        max-height: calc(100vh - 70px);
        border: 0 none;
        border-radius: 5px;
        border: 1px solid rgba($blue, 0.2);
        width: auto;
        height: auto;
        padding: 5px 0px;
        text-align: left;
        > li {
          text-align: left;
          white-space: nowrap;
          line-height: 30px;
          cursor: pointer;
          color: $dark;
          padding: 0 10px;
          span {
            background-color: transparent;
            border: 0 none;
            border-radius: 0;
            display: inline;
            color: $dark;
            padding: 0 0 0 10px;
          }
          i {
            color: $dark;
            font-size: 10px;
            line-height: 1em;
          }
          &:hover {
            background-color: rgba($blue, 0.2);
            //color: #FFFFFF;
          }
        }
      }
    }

    &.dropdown {
      position: relative;
      > span {
        width: auto;
        padding-left: 10px;
        padding-right: 10px;
        font-weight: $medium;
        font-size: 14px;
        padding-left: 15px;
        border-radius: 50px;
        &:before {
          font-family: "Material Icons";
          content: "expand_more";
          font-size: 12px;
          position: relative;
          left: -7px;
        }
      }
      &.active > span:before {
        content: "expand_less";
      }
      > div {
        text-align: left;
        > ul {
          display: flex;
          flex-wrap: wrap;
          > li {
            text-align: left;
            &.disabled {
              opacity: 0.5;
              i {
                color: $dark;
              }
            }
            &.optionlabel, &.optionlabel:hover {
              background-color: #ffffff;
              cursor: default;
              line-height: 25px;
              span {
                font-size: 0.8em;
              }
            }
            &.indented {
              > span, > a {
                padding-left: 15px;
              }
            }
          }
          &.theme-select {
            li {
              height: 40px;
              display: flex;
              line-height: 30px;
              padding-top: 5px;
              padding-bottom: 5px;
              span:first-child {
                flex-basis: 30px;
                width: 30px;
                height: 30px;
                border-radius: 50%;
                display: inline-block;
                padding: 0;
                margin: 0;
                margin-right: 10px;
                background-position: center;
                background-size: cover;
                border: 1px solid rgba($blue, 0.2);
              }
            }
          }
          &.key-select {
            display:flex;
            flex-wrap: wrap;
            width: 150px;
            li {
              //flex-basis: 50%;
              //width: 50%;
              &.col3, &.col3:hover {
                background-color: transparent;
              }
              &.col3 span {
                border-radius: 50%;
                //height: 38px;
                //width: 38px;
                display: block;
                cursor: pointer;
                user-select: none;
                background-color: $lightblue;
                border: 1px solid rgba($blue, 0.2);
                height: 30px;
                width: 30px;
                text-align: center;
                padding: 0;
              }
              &.active span {
                background-color: $blue;
                color: #FFFFFF
              }
              &.selected {
                background-color: $blue;
                color: #FFFFFF;
                span {
                  color: #FFFFFF;
                }
              }
            }
          }
          &.collab-select {
            li {
              i {
                font-size: 12px;
              }
            }
          }
        }
      }
    }

    &:hover {
      > span,
      > a {
        //border: 1px solid rgba($blue, 0.2);
        background-color: rgba($blue, 0.2);
      }
    }
    &.active {
      > span,
      > span > span,
      > a {
        background-color: $blue;
        color: #ffffff;
        i {
          color: #ffffff;
        }
      }
    }
    &.disabled,
    &.disabled:hover {
      //filter: blur(0.5px);
      opacity: 0.5;
      > span,
      > a {
        cursor: default;
        background-color: $lightblue;
      }
    }
  }

  .support-channels-row {
    &:hover {
      background: #FFF;
    }
    > span {
      display: flex;
      justify-content: space-between;
      padding: 10px 10px 0px 10px;
      > span, > a {
        display: block;
        width: 30px;
        height: 30px;
        text-align: center;
        line-height: 30px;
        &:hover {
          background-color: rgba($blue, 0.2);
        }
      }
      i {
        font-size: 20px;
        line-height: 30px;
      }
    }
    
  }

  .tb-mobile-align-options {
    display: none;
  }
  @media only screen and (max-device-width: $max-width-tablet) {
    .separator {
      display: none;
    }
    .toggle-align, .pb-theme, .tb-meta, .key-dropdown {
      display: none;
    }
    .tb-mobile-align-options {
      display: block;
    }
  }
  @media only screen and (max-device-width: $max-width-phone) {
    .tb-undo, .tb-redo, .toggle-edit, .tb-print, .tb-collab {
      display: none;
    }
  }
}

.start-page .toolbar {
  position: absolute;
  left: 0;
  right: 0;
  justify-content: flex-end;
  background: transparent;
  border-bottom: 0 none;
  padding-right: 20px;
  box-shadow: none;
}