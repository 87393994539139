$toolbar-editor-space: 30px;

.meta-editor {
  text-align: left;
  p {
    font-size: 16px;
    //margin: 0 auto 15px auto;
    //max-width: 400px;
  }
  .meta-editor-list {
    ul {
      margin: 0 0 $toolbar-editor-space 0;
      li {
        text-align: left;
        cursor: pointer;
        line-height: 40px;
        display: flex;
        border-bottom: solid rgba($dark, 0.05) 1px;
        &:first-child {
          border-top: solid rgba($dark, 0.05) 1px;
        }
        span {
          flex-basis: 1;
        }
        &:hover {
          background-color: rgba($blue, 0.2);
        }
        span:first-child {
          flex-grow: 1;
        }
        span:nth-child(2) {
          text-align: right;
        }
        span:last-child {
          cursor: pointer;
          padding-left: 10px;
          padding-right: 10px;
          width: 1em;
        }
        i {
          color: $dark;
          font-size: 12px;
          line-height: 1em;
          display: inline-block;
          &:hover {
            color: $blue;
          }
        }
      }
    }
  }

  h3,
  h4 {
    margin: 0;
    padding: 0;
    margin-bottom: 5px;
  }
  input {
    border-top: 0 none;
    border-left: 0 none;
    border-right: 0 none;
    width: 100%;
  }
  .dropdown {
    margin-bottom: 5px;
  }
  .btn-group {
    margin-top: 10px;
    button,
    i {
      text-align: center;
    }
  }
  button {
    display: block;
  }
  .grayed {
    font-size: 0.8em;
  }
}

.theme-editor {
  h2 {
    margin: 0;
    padding: 0;
    margin-bottom: 5px;
  }
  h4 {
    margin: 0;
    padding: 0;
    font-size: 14px;
    font-weight: $regular;
  }
  p {
    margin: 0;
    padding: 0;
    margin-bottom: 5px;
  }
  .unsplash-user {
    display: flex;
    flex-wrap: nowrap;
    span {
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      border-radius: 50%;
      height: 20px;
      width: 20px;
      margin-right: 6px;
    }
    a,
    a:hover,
    a:link {
      word-wrap: none;
      white-space: nowrap;
      font-size: 14px;
      line-height: 20px;
      color: $dark;
      text-decoration: none;
    }
  }
  .current-theme {
    > div {
      display: flex;
      align-content: stretch;
      > div:first-child {
        height: 150px; //100px;
        min-width: 111px; // 74px;
        //flex-basis: 111px;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
      }
      > div:last-child {
        //flex-basis: 1;
        padding-left: 15px;
        text-align: left;
        h4 {
          margin-bottom: 5px;
        }
        .unsplash-user {
          margin-bottom: 5px;
          span {
            height: 30px;
            width: 30px;
          }
          a {
            font-size: 16px;
            line-height: 30px;
          }
        }
        p {
          font-size: 13px;
          font-weight: $light;
          font-style: italic;
          opacity: 0.8;
        }
      }
    }
  }
  .tags {
    margin-left: -3px;
    margin-right: -3px;
    span {
      display: inline-block;
      padding: 0 6px;
      line-height: 26px;
      font-size: 14px;
      font-weight: $regular;
      background-color: rgba($blue, 0.05);
      border: 1px solid rgba($blue, 0.2);
      margin: 3px;
      border-radius: 10px;
      cursor: pointer;
      &.active {
        background-color: $blue;
        color: #ffffff;
      }
    }
  }
  .load-more {
    text-align: center;
    button {
      display: block;
      margin: 15px auto 0 auto;
    }
  }
  .unsplash-results {
    display: flex;
    flex-wrap: wrap;
    //margin-left: -3px;
    //margin-right: -3px;
    > div {
      height: 100px;
      width: 74px;
      margin: 3px;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      cursor: pointer;
      position: relative;
      border: 1px solid rgba($blue, 0.2);
      &:hover {
        border-color: $blue;
      }
      > div {
        display: none;
        position: absolute;
        z-index: 10;
        bottom: -15px;
        background-color: #fff;
        border: 1px solid rgba($blue, 0.2);
        border-radius: 6px;
        left: 50%;
        transform: translateX(-50%);
        padding: 5px;
        span {
          background-position: center;
          background-repeat: no-repeat;
          background-size: cover;
          border-radius: 50%;
          height: 20px;
          width: 20px;
          margin-right: 6px;
        }
        a,
        a:hover,
        a:link {
          word-wrap: none;
          white-space: nowrap;
          font-size: 14px;
          line-height: 20px;
          color: $dark;
          text-decoration: none;
        }
      }
      &:hover > div {
        display: flex;
        flex-wrap: nowrap;
      }
    }
  }
}

.collab-editor {
  text-align: left;
  p {
    font-size: 16px;
    margin: 0 0 15px 0;
    max-width: 400px;
  }
  .collab-share-option {
    margin: 0 0 30px 0;
  }
  .collab-share-buttons {
    display: flex;
    button {
      width: 32px;
      height: 32px;
      border-radius: 50%;
      margin-right: 10px;
      svg {
        width: 32px;
        height: 32px;
        border-radius: 50%;
      }
    }
  }
  .collab-editor-list {
    margin: 0 0 30px 0;
    ul {
      li {
        text-align: left;
        cursor: pointer;
        line-height: 40px;
        display: flex;
        border-bottom: solid rgba($dark, 0.05) 1px;
        &:first-child {
          border-top: solid rgba($dark, 0.05) 1px;
        }
        span {
          flex-basis: 1;
        }
        &:hover {
          background-color: rgba($blue, 0.2);
        }
        &.disabled:hover {
          cursor: default;
          background-color: #ffffff;
        }
        &.awaiting {
          span {
            font-style: italic;
          }
        }
        .grayed {
          font-size: 0.8em;
        }
        span:first-child {
          flex-grow: 1;
          i {
            opacity: 0.2;
          }
        }
        span:nth-child(2) {
          text-align: right;
        }

        /*
                span:last-child {
                    cursor: pointer;
                    padding-left: 10px;
                    padding-right: 10px;
                    width: 1em;
                }
                i {
                    color: $dark;
                    font-size: 12px;
                    line-height: 1em;
                    display: inline-block;
                    &:hover {
                        color: $blue;
                    }
                }
                */
      }
    }
  }
  .dropdown {
    margin-bottom: 10px;
  }
  input {
    margin-bottom: 10px;
    border-top: 0 none;
    border-left: 0 none;
    border-right: 0 none;
    width: 100%;
  }
  .btn-group {
    margin-top: 10px;
    button,
    i {
      text-align: center;
    }
  }
  button {
    display: block;
  }
}
