@media print {
    body {
        overflow: visible;
    }
    header, footer, .toolbar {
        display: none;
    }
    .editor {
        background-color: #FFF !important;
        background-image: none !important;
        height: auto;
        width: auto;
        overflow: visible;
        max-width: none;
    }
    .editor #scrollable {
        height: auto;
    }
    .editor .document-area {
        margin: 0;
        box-shadow: none;
        width: 100%;
        max-width: none;
        margin: 0;
    }
    .sb-container {
        display: none;
    }
}