$chord-editor-width: 340px;
$chord-editor-padding: 10px;
$chord-editor-inner-width: $chord-editor-width - $chord-editor-padding - $chord-editor-padding;

#chord-editor-root {
  width: 0;
  height: 0;
}
.chord-editor {
  position: absolute;
  z-index: 3;
  padding: $chord-editor-padding;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .16);
  width: $chord-editor-width;

  .chord-arrow {
    position: absolute;
    top: -30px;
    left: 10px;
    width: 0; 
    height: 0; 
    text-shadow: 0px -2px rgba(0, 0, 0, .16);
    color: #FFFFFF;
    font-size: 35px;
    line-height: 40px;
    font-family: 'Roboto Condensed', sans-serif;
  }
  .chord-close {
    right: 10px;
    top: 10px;
    position: absolute;
    border-radius: 2px;
    width: 20px;
    height: 20px;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    cursor: pointer;
    color: #AAAAAA;
    &:hover {
      background-color: rgba($blue, 0.2);
      color: #000000;
    }
  }
  
}
.chord-input-wrapper {
  display: flex;
  span {
    color: #AAAAAA;
    cursor: pointer;
    user-select: none;
    &:hover {
      background-color: rgba($blue, 0.2);
      color: $dark;
    }
  }
}
.chord-input {
  height: 25px;
  //width: $chord-editor-inner-width;
  font-size: 0.8em;
  border: 0 none;
  border-bottom: rgba(0, 0, 0, .16) solid 1px;
  text-align: left;
  font-size: 16px;
  line-height: 25px;;

  font-family: 'Roboto', sans-serif; // 'BravuraText', sans-serif;
  font-weight: 400;
}

.chords-panel {
  max-width: $chord-editor-inner-width;
  .chords-panel-page {
    margin: 5px 0;
    text-align: left;
    width: 100%;

    p {
      font-size: 15px;
      line-height: 1.4em;
      font-family: 'Quicksand', sans-serif;
    }

    .chords-panel-row {
      width: 100%;
      display: flex;
      flex-wrap: no-wrap;
      justify-content: center;
      > div {
        flex-basis: percentage(math.div(1,7));
        text-align: center;
        padding: 0 2px;
        span {
          font-size: 17px;
          font-family: 'Roboto Condensed', sans-serif; // 'BravuraText', sans-serif;
          font-weight: 400;
          width: 40px;
          height: 40px;
          border: 1px solid rgba($blue, 0.2);
          border-radius: 5px;
          margin: 5px auto;
          display: block;
          line-height: 40px;
          background-color: $lightblue;
          color: #AAAAAA;
          cursor: pointer;
          user-select: none;
          &:hover {
            background-color: rgba($blue, 0.2);
            color: $dark;
          }
        }
      }
    }
    
  }

  .chord-description {
    text-align: left;
    div, span, p {
      font-size: 15px;
      line-height: 24px;
      color: $dark;
    }
  }
}