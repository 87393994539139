.flex-slider {
  
  .fs-body {
      max-width: $editorMaxWidth;
      overflow: hidden;

      .fs-inner {
          width: auto;
          display: flex;
          flex-wrap: nowrap;
          flex-direction: row;
          // overflow-y: hidden;
          align-items: flex-start;
          -webkit-overflow-scrolling: touch;
          -ms-overflow-style: -ms-autohiding-scrollbar;

          transition: margin 0.7s ease-in-out;

          > div {
              width: 100%;
              max-width: $editorMaxWidth;
              flex-shrink: 0;
          }
      }
  }

  &[data-fs-slide="1"] .fs-body .fs-inner {
      //transform: translateX(0);
      margin-left: 0;
  }
  &[data-fs-slide="2"] .fs-body .fs-inner {
      //transform: translateX(-100%);
      margin-left: -100%;
  }
  &[data-fs-slide="3"] .fs-body .fs-inner {
      //transform: translateX(-200%);
      margin-left: -200%;
  }
  &[data-fs-slide="4"] .fs-body .fs-inner {
      //transform: translateX(-300%);
      margin-left: -300%;
  }
  &[data-fs-slide="5"] .fs-body .fs-inner {
      //transform: translateX(-400%);
      margin-left: -400%;
  }

  &[data-fs-slide="1"] .fs-body .fs-inner.fs-show-hide {
      transform: translateX(0);
      > div {
          display: block;
      }
  }

  &[data-fs-slide="2"] .fs-body .fs-inner.fs-show-hide {
      transform: translateX(0);
      > div {
         
          &:nth-child(2) {
              display: block;
          }
          
          &:nth-child(1) {
              display: none;
          }
      }
  }

  &[data-fs-slide="3"] .fs-body .fs-inner.fs-show-hide {
      transform: translateX(0);
      > div {
          &:nth-child(3) {
              display: block;
          }

          &:nth-child(1) {
              display: none;
          }

          &:nth-child(2) {
              display: none;
          }
      }
  }

  &[data-fs-slide="4"] .fs-body .fs-inner.fs-show-hide {
      transform: translateX(0);
      > div {
          &:nth-child(4) {
              display: block;
          }


          &:nth-child(1) {
              display: none;
          }

          &:nth-child(2) {
              display: none;
          }

          &:nth-child(3) {
              display: none;
          }
      }
  }

  &[data-fs-slide="5"] .fs-body .fs-inner.fs-show-hide {
      
      transform: translateX(0);
      > div {
          
          &:nth-child(5) {
              display: block;
          }

          &:nth-child(1) {
              display: none;
          }

          &:nth-child(2) {
              display: none;
          }

          &:nth-child(3) {
              display: none;
          }

          &:nth-child(4) {
              display: none;
          }
      }
  }
}

