.login-wrapper {
  width: 100vw;
  height: 100vh;

  //background-color: $bgGray;
  background-color: #000000;
  //background-image: url("/static/images/kelly-sikkema-ia1p6fqftnQ-unsplash.jpg");
  background-image: url("#{$public-path}/static/images/front-bg.jpg");
  background-size: auto 1440px;
  background-repeat: no-repeat;
  background-position: center;
}

.login-screen {
  text-align: center;
  top: 50%;
  position: relative;
  transform: translateY(-50%); //  rotate(-2deg)
  //margin-top: -300px;
  //margin-left: -100px;

  h1 {
    margin: 10px 0;
    font-size: 36px;
  }

  .login-box {
    display: inline-block;
    text-align: left;
    margin-left: auto;
    margin-right: auto;

    border-radius: 5px;
    z-index: 99;
    position: relative;
  }

  .login-content {
    position: relative;
  }

  .logging-in {
    margin-top: 20px;
  }

  .start-writing {
    padding-top: 20px;
    padding-bottom: 22px;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    button {
      width: 250px;
      height: 40px;
      line-height: 40px;
      height: 40px;
      border-width: 0;
      background: rgba(#ffffff, 0.2);
      color: #737373;
      border-radius: 5px;
      white-space: nowrap;
      box-shadow: 1px 1px 0px 1px rgba(0, 0, 0, 0.05);
      transition-property: background-color, box-shadow;
      transition-duration: 150ms;
      transition-timing-function: ease-in-out;
      padding: 0;
      position: relative;
      margin-bottom: 10px;
      span {
        height: 40px;
        line-height: 40px;
        font-weight: 600;
        //font-size: 15px;
      }
      &:focus,
      &:hover {
        box-shadow: 1px 4px 5px 1px rgba(0, 0, 0, 0.1);
        outline: none;
      }
    }
  }

  .showcase-video {
    video {
      width: 600px;
      height: auto;
      border: solid 1px $dark;
    }
  }
}

@media (max-width: 1440px) {
  .login-wrapper {
    background-size: auto 900px;
    h1 {
      font-size: 30px;
    }
  }
  .login-screen {
    .login-box {
      //transform: translateX(-65px) translateY(-100px);
      .showcase-video {
        video {
          width: 350px;
        }
      }
    }
  }
  
}

@media (max-width: 575.98px) {
  .login-wrapper {
    background-size: 1000px auto;
    background-position: center;
  }

  .login-screen {
    top: 50%;
    position: relative;
    //transform: translateY(-50%) rotate(0);
    .login-box {
      max-width: 90%;
      //transform: translateX(0) translateY(0);
      .showcase-video {
        display: none;
      }
    }
    text-align: center;
    h1 {
      font-size: 24px;
    }
  }
}
