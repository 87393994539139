.sm-editor {
  position: absolute;
  z-index: 30;
  background: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .16);
  padding: 30px 10px 10px 10px;
  min-width: 200px;
  min-height: 100px;
  .sm-editor-arrow {
    position: absolute;
    top: -30px;
    left: 10px;
    width: 0;
    height: 0;
    text-shadow: 0px -2px rgba(0, 0, 0, .16);
    color: #ffffff;
    font-size: 35px;
    line-height: 40px;
    font-family: 'Roboto Condensed', sans-serif;
  }
  &.apos-5 .sm-editor-arrow { left: 5px; }
  &.apos-10 .sm-editor-arrow { left: 10px; }
  &.apos-15 .sm-editor-arrow { left: 15px; }
  &.apos-20 .sm-editor-arrow { left: 20px; }
  &.apos-25 .sm-editor-arrow { left: 25px; }
  &.apos-30 .sm-editor-arrow { left: 30px; }

  &.apos-middle .sm-editor-arrow { left: 50%; transform: translateX(-10px); }

  &.apos-right-5 .sm-editor-arrow { left: auto; right: 0; transform: translateX(-5px); }
  &.apos-right-10 .sm-editor-arrow { left: auto; right: 0; transform: translateX(-10px); }
  &.apos-right-15 .sm-editor-arrow { left: auto; right: 0; transform: translateX(-15px); }
  &.apos-right-20 .sm-editor-arrow { left: auto; right: 0; transform: translateX(-20px); }
  &.apos-right-25 .sm-editor-arrow { left: auto; right: 0; transform: translateX(-25px); }
  &.apos-right-30 .sm-editor-arrow { left: auto; right: 0; transform: translateX(-30px); }

  .sm-editor-close {
    right: 10px;
    top: 10px;
    position: absolute;
    border-radius: 2px;
    width: 20px;
    height: 20px;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    cursor: pointer;
    color: #aaaaaa;
    &:hover {
      background-color: rgba($blue, 0.2);
      color: #000000;
    }
  }

  .dropdown-list {
    display: block;
    margin-left: -10px;
    margin-right: -10px;
    li, span {
      font-size: 15px;
    }
    i {
      font-size: 14px;
    }
  }

  p {
    font-size: 13px;
    margin: 10px 0 10px 0;
  }
}


.sm-editor-tabs, .dropdown li.tabs {
  background: #FFFFFF;
  &:hover { background: #FFFFFF; }
  margin: 5px 0;
  display: flex;
  width: 100%;
  > div {
    flex-basis: 50%;
    text-align: center;
    //
    > span {
      font-size: 14px;
      width: auto;
      height: 30px;
      border-bottom: 1px solid rgba($blue, 0.2);
      margin: 10px auto 0px auto;
      display: block;
      line-height: 30px;
      font-family: 'Roboto Condensed', sans-serif; // 'BravuraText', sans-serif;
      font-weight: 400;
      cursor: pointer;
      user-select: none;
      color: #AAAAAA;
      &:hover {
        background-color: rgba($blue, 0.2);
      }
      &.active {
        //background-color: $blue;
        border-bottom: 3px solid $blue;
        color: $blue;
        font-weight: 600;
      }

      span {
        line-height: 20px;
        height: 20px;
      }
    }
  }
}