
    .browser-options {
      height: 50px;
      line-height: 50px;
      display: flex;
      justify-content: space-between;
      margin-bottom: 30px;
      padding: 0 10px;
      > div {
        display: flex;
        > div {
          > span {
            display: block;
            font-size: 14px;
            font-family: 'Roboto', sans-serif;
            font-weight: 300;
            padding: 0 10px;
            cursor: pointer;
            i {
              opacity: 0.8;
              width: 16px;
            }
            .icon-song {
              transform:translateY(2px);
              &:before {
                background-size: 120%;
              }
            }
          }
          &.disabled {
            display: none;
            > span {
              cursor: default;
            }
          }
          &:hover {
            // background-color: $lightblue;
            background-color: rgba($blue, 0.2);
          }
        }
      }

      .dropdown > label {
        border: 0 none;
        height:auto;
        width: auto;
        padding: 0 10px;
        line-height: 50px;
        font-size: 14px;
        font-family: 'Roboto', sans-serif;
        font-weight: 300;
        i {
          margin: 0;
        }
        &:before {
          content: "";
          display: none;
        }
      }
    }

@media only screen and (max-device-width: $max-width-phone) {
  .browser-options {
    > div {
      > div {
        > span {
          padding: 0 5px;
          font-size: 12px;
        }
      }
    }
    .dropdown > label {
      font-size: 14px;
      padding: 0 5px;
    }
  }
}