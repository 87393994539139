.alert-overlay {
    position: fixed;
    z-index: 999999;
    top: -5000px;
    right: -5000px;
    bottom: -5000px;
    left: -5000px;
    display: block;
    background: rgba(43, 46, 56, 0.5);
  }
  .alert-wrapper {
    position: fixed;
    z-index: 2000000;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    overflow: auto;
    text-align: center;
    -webkit-overflow-scrolling: touch;
    padding: 10px 10px 0;
    .alert {
      position: relative;
      background-color: $white;
      margin-top: calc(50vh - 162px);
      transform: translateY(-50%);
      margin-left: auto;
      margin-right: auto;
      max-width: 480px;
      border: 1px solid $white;
      //box-shadow: 0 2px 8px 1px $color-transparent-black;
      box-shadow: rgba(60, 64, 67, 0.15) 0px 0px 10px 10px;
      border-radius: 4px;
      //font-family: $font-raleway;
      font-size: 15px;
      padding: 50px 25px 25px 25px;
        
      .alert-icon {
          i {
            color: $blue;
            font-size: 30px;
          }
      }
      .error-image {
        //background-image: $icon-error;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        height: 97px;
        width: 106px;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 30px;
      }
  
      h2 {
        font-size: 25px;
        font-weight: $semibold;
      }
     
      p {
        font-size: 15px;
        color: $dark;
        margin-bottom: 50px;
        //font-family: $font-sarabun;
        font-size: 15px;
        letter-spacing: 0.5px;
        padding: 0;
      }
      .btn-group {
        justify-content: center;
      }
      button {
        display: block;
        width: 200px;
        &:first-child {
          margin-right: 20px;
        }
      }
    }
  }