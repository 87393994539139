.browser-page {
  font-size: $baseFontSize;
  background: $bgGray;
  height: calc(100vh);
  overflow: auto;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center top;

  .doc-list-wrapper {
    background-color: #ffffff;
    padding: 0 0 30px 0;
    margin: 30px auto 30px auto;
    max-width: $editorMaxWidth;
    min-height: calc(100vh - 60px - 30px - 30px);
    text-align: left;
    box-shadow: rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
    position: relative;


    .loading-container {
      margin: 20px auto;
      display: inline-block;
      padding: 0 10px 0 100px;
    }

    h2 {
      margin: 0;
      line-height: 1.5rem;
      font-size: 15px;
      padding: 0;
      margin: 0;
      width: 100%;
      font-weight: $light;
    }
  }
  @media only screen and (max-device-width: $max-width-phone) {
    .doc-list-wrapper {
      margin: 0px auto 0px auto;
    }
  }
}


    




    
    
    










