html,
body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  overflow-y: hidden;
  color: $dark;
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}


div, h1, h2, h3 {
  outline: none;
}
h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5 {
  margin: 0.8em 0 0.2em 0;
}

input {
  border-width: 1px;
  border-color: #afafd3;
  border-style: solid;
}
input:focus::-webkit-input-placeholder {
  opacity: 0;
}
input:focus:-moz-placeholder {
  opacity: 0;
}
input:focus::-moz-placeholder {
  opacity: 0;
}
input:focus:-ms-input-placeholder {
  opacity: 0;
}
input,
select,
option,
textarea {
  font-size: 15px;
  color: #1a1a1a;
  letter-spacing: -0.15px;
  font-weight: $regular;
  line-height: 26px;
  &.invalid {
    border-color: $red;
  }
}
textarea {
  border: 0.5px solid rgba($blue, 0.2);
  padding: 5px;
  background: #FFF;
  font-size: 12px;
  line-height: 16px;
  min-height: 16px;
  width: 100%;
  overflow: hidden;
  resize: none;
  border-radius: 5px;
}
input,
select,
option {
  height: 40px;
  line-height: 26px;
}
input,
option {
  padding: 6px 8px;
}
input:focus,
textarea:focus,
select:focus {
  outline: none;
}

button, .button {
  border-radius: 5px;
  height: 40px;
  line-height: 40px;
  display: inline-block;
  cursor: pointer;
  user-select: none;
  background-color: $lightblue;
  border: 1px solid rgba($blue, 0.2);

  width: auto;
  padding: 0 10px;

  font-family: 'Roboto', sans-serif;
  font-weight: $regular;
  font-size: 15px;

  color: $dark;
  i {
    color: $dark;
    font-size: 12px;
    margin-right: 6px;
  }
  &.small {
    height: 30px;
    line-height: 30px;
    padding: 0 10px;
    i {
      font-size: 11px;
      margin-right: 4px;
    }
  }
  &:hover {
    color: $dark;
    background-color: $midblue;
  }
  &.highlighted {
    background-color: $blue;
    color: #FFF;
  }
  &.highlighted:hover {
    background-color: rgba($blue, 0.9);
    color: #FFF;
  }
  &.inactive {
    opacity: 0.5;
    cursor: default;
  }
}

.btn-group {
  display: flex;
  button {
    margin-right: 5px;
  }
  .ml-auto {
    margin-left: auto;
  }
  &.center {
    justify-content: center;
  }
}

a, a:link, a:active {
  text-decoration: none;
  color: $dark;
  
}
a:hover, a:active {
  text-decoration: none;
}
ul, li {
  margin:0;
  padding: 0;
  text-indent: 0;
  list-style-type: none;
}

.cursor-pointer {
  cursor: pointer;
}

.color-blue {
  color: $blue;
}
.color-green {
  color: $green;
}
.grayed {
  color: $dark;
  opacity: 0.5;
}

.help-mark {
  position: relative;
  display: inline-block;
  .fa-question-circle {
    color: $blue;
    font-size: 0.8em;
    top: -0.4em;
    left: 0.5em;
    position: relative;
  }
}

.video-wrapper {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 25px;
  height: 0;
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}


i.flag {
  display: inline-block;
  color: #000000;
  font-style: normal;
}
