.dropdown {
  position: relative;
  text-align: left;
  font-family: 'Quicksand', sans-serif;
  > label {
    height: 38px;
    line-height: 38px;
    display: block;
    cursor: pointer;
    user-select: none;
    border: 1px solid rgba($blue, 0.2);

    width: auto;
    padding-left: 15px;
    padding-right: 10px;
    font-size: 14px;
    border-radius: 5px;

    &:before {
      font-family: "Material Icons";
      content: "expand_more";
      font-size: 12px;
      position: relative;
      left: -7px;
    }
    i {
      margin-right: 5px;
      font-size: 12px;
    }
  }
  &.ellipsis {
    display: inline-block;
    > label {
      display: inline-block;
      border: 0 none;
      &:before {
        content: "";
        display: none;
      }
    }
  }
  &.active > span:before {
    content: "expand_less";
  }
}
.dropdown {
  > div {
    position: absolute;
    z-index: 10;
    background-color: #ffffff;
    box-shadow: rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
    max-height: calc(100vh - 70px);
    border: 0 none;
    border-radius: 5px;
    border: 1px solid rgba($blue, 0.2);
    width: auto;
    height: auto;
    padding: 5px 0px;
    text-align: left;
    > i {
      width: 100%;
    }
  }
  &.dropdown-right > div {
    right: 0;
  }
  &.dropdown-up > div {
    bottom: 0;
  }
}

.dropdown > div > ul,
ul.dropdown-list {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  > li {
    > div {
      // Submenu
      position: absolute;
      left: 95%;
      top: -0.5em;
      z-index: 20;
      background-color: #ffffff;
      box-shadow: rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
      border-radius: 5px;
      border: 1px solid rgba($blue, 0.2);
      max-width: 200px;
      width: 200px;
      text-align: left;
      p {
        font-size: 14px;
        line-height: 18px;
        margin: 0;
        padding: 10px;
      }

      ul.content-between-dropdown {
        li {
          display: flex;
          justify-content: space-between;
        }
      }
    }
  }
  li {
    text-align: left;
    white-space: nowrap;
    line-height: 30px;
    cursor: pointer;
    color: $dark;
    padding: 0 10px;
    flex-basis: 100%;
    font-size: 16px;
    position: relative;

    &.devider,
    &.devider:hover {
      height: 3px;
      padding: 1px 0;
      background: #ffffff;
      &::before {
        content: '';
        display: block;
        width: 100%;
        height: 1px;
        background-color: rgba($dark, 0.05);
      }
    }
    &.tabs {
      margin: 0;
      padding-left: 0;
      padding-right: 0;
      position: relative;
      > div > span {
        margin: 0;
        line-height: 40px;
        height: 40px;
      }
    }
    &:hover {
      background-color: rgba($blue, 0.2);
    }
    > span {
      text-align: left;
      background-color: transparent;
      border: 0 none;
      border-radius: 0;
      display: inline;
      color: $dark;
      white-space: nowrap;
      font-size: 16px;
      > i {
        margin-right: 6px;
        font-size: 16px;
      }
    }
    i {
      color: $blue;
      font-size: 10px;
      line-height: 1em;
      display: inline-block;
    }
    &.only-icon {
      i {
        text-align: center;
        width: 100%;
        font-size: 14px;
      }
    }

    &.active,
    &.active:hover {
      background-color: $blue;
      color: #FFF;
      > span, span, i {
        color: #ffffff !important;
      }
    }

    &.col2 {
      flex-basis: 50%;
    }
    &.col3 {
      flex-basis: 33.333%;
    }
    &.em {
      font-size: 1em;
    }
    &.text-center {
      text-align: center;
    }
    &.awaiting {
      span {
        font-style: italic;
      }
    }
    .grayed {
      font-size: 0.8em;
    }
    &.selected {
      background-color: $blue;
      color: #ffffff;
      span {
        color: #ffffff;
      }
    }
    &.inactive {
      cursor: default;
      span {
        opacity: 0.5;
        cursor: default;
      }
      &:hover {
        background-color: white;
      }
    }
    &.optionlabel, &.optionlabel:hover {
      background-color: #ffffff;
      cursor: default;
      line-height: 25px;
      span {
        font-size: 0.8em;
        opacity: .8;
      }
    }
    span.value-selected {
      background-color: $blue;
      color: #ffffff;
      border-radius: 2px;
      margin-left: 10px;
      padding-left: 10px;
      padding-right: 10px;
      display: inline-block;
      line-height: 26px;
      font-style: normal;
      font-family: 'Roboto Condensed', sans-serif;
      font-size: 15px;
    }

    span.value-marked {
      //border: $blue 1px solid;
      background-color: $blue;
      color: #ffffff;
      border-radius: 2px;
      margin-left: 10px;
      padding-left: 10px;
      padding-right: 10px;
      display: inline-block;
      line-height: 26px;
      font-style: normal;
      font-family: 'Roboto Condensed', sans-serif;
      font-size: 15px;
    }
  }

  
}
