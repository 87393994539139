.help-menu {
	position: absolute;
	right: 30px;
	bottom: 20px;
	> span {
		cursor: pointer;
		display: block;
		margin: 0;
		border: solid 1px $blue;
		border-radius: 50%;
		background-color: transparent;
		color: $blue;
		font-size: 20px;
		line-height: 38px;
		width: 38px;
		height: 38px;
		text-align: center;
		font-family: 'Roboto', sans-serif;
		opacity: 0.3;
		transition: opacity 200ms ease;
		&:hover {
			background-color: rgba($blue, 0.9);
			color: #fff;
			//border: solid 1px #fff;
		}
	}
	&:hover > span {
		opacity: 1;
	}
	> div {
		transform: translateX(-20px) translateY(-20px);
	}
}

.contact-form {
	text-align: left;
	h2 {
		margin-bottom: 0.5em;
	}
	textarea {
		margin-bottom: 0.5em;
	}
}
/*
#Smalltalk {
	transform: translateX(200px);
}
body.show-smalltalk {
	transform: translateX(0px);
}
*/
