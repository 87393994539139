.dropdown-button {
  position: relative;
  text-align: left;
  display: flex;
  border-radius: 5px;
  > span {
    display: block;
  }
  > span:first-child {
    width: 25px;
    text-align: center;
    position: relative;
    cursor: pointer;
    &::before {
      font-family: "Material Icons";
      content: "expand_more";
      font-size: 13px;
      position: absolute;
      top: 50%;
      left: 10px;
      transform: translateY(-50%);
    }

    @media (max-width: $max-width-phone) {
      width: 35px;
    }
  }
  &.dropdown-open {
    > span:first-child:before {
      content: "expand_less";
    }
  }
  > span:nth-child(2), > span:nth-child(3) {
    flex-grow: 1;

    height: 40px;
    line-height: 40px;
    display: block;
    cursor: pointer;
    user-select: none;

    font-family: 'Roboto', sans-serif;
    font-weight: $regular;
    font-size: 14px;

    width: auto;
    padding-left: 10px;
    padding-right: 10px;
    font-size: 14px;
  }
  &.small {
    > span:last-of-type {
      height: 30px;
      line-height: 30px;
    }
  }
  &.highlighted {
    background-color: $blue;
    > span {
      color: #fff;
    }
  }
  > div {
    position: absolute;
    z-index: 10;
    background-color: #ffffff;
    box-shadow: rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
    max-height: calc(100vh - 70px);
    border-radius: 5px;
    border: 1px solid rgba($blue, 0.2);
    width: auto;
    height: auto;
    padding: 5px 0px;
    text-align: left;
    top: 100%;
    ul {
      li {
        position: relative;
        padding: 0px 10px;
        cursor: pointer;
        background-color: #ffffff;
        span {
          width: 100%;
          white-space: nowrap;
          font-size: 15px;
          line-height: 25px;
          font-family: 'Roboto', sans-serif;
          font-weight: 300;
          @media (max-width: $max-width-phone) {
            line-height: 35px;
          }
        }
        &:not(.inactive):hover {
          background-color: $blue;
          span {
            color: #fff;
          }
          i:before {
            background-image: url("data:image/svg+xml,%3Csvg width='33' height='33' viewBox='0 0 33 33' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M0 16.5C0 17.8807 1.11929 19 2.5 19H13C13.5523 19 14 19.4477 14 20V30.5C14 31.8807 15.1193 33 16.5 33C17.8807 33 19 31.8807 19 30.5V20C19 19.4477 19.4477 19 20 19H30.5C31.8807 19 33 17.8807 33 16.5C33 15.1193 31.8807 14 30.5 14H20C19.4477 14 19 13.5523 19 13V2.5C19 1.11929 17.8807 0 16.5 0C15.1193 0 14 1.11929 14 2.5V13C14 13.5523 13.5523 14 13 14H2.5C1.11929 14 0 15.1193 0 16.5Z' fill='%23FFFFFF'/%3E%3C/svg%3E%0A");
          }
        }
        &.inactive {
          cursor: default;
          span {
            color: $dark;
            opacity: 0.5;
            cursor: default;
          }
          &:hover {
            background-color: white;
          }
        }
        div {
          position: absolute;
          left: 95%;
          top: -0.5em;
          z-index: 20;
          background-color: #ffffff;
          box-shadow: rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
          border-radius: 5px;
          border: 1px solid rgba($blue, 0.2);
          max-width: 200px;
          width: 200px;
          padding: 10px;
          p {
            font-size: 14px;
            line-height: 18px;
            margin: 0;
            padding: 0;
            img {
              width: 80%;
              height: auto;
              margin-top: 10px;
            }
          }
        }
      }
    }
  }
}
