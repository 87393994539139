.selected-rows-toolbar {
  position: absolute;
  display: flex;
  width: auto;
  background: $blue;
  z-index: 100;
  margin-top: 0;

  // Activate the following 2 lines for full width
  //width: 794px;
  //max-width: 100vw;

  &.sticky {
    position: fixed;
    transform: none;
    top: 0;
  }
  .checkbox {
    border-color: #FFF;
    text-align: center;
    i {
      color: #FFF;
      text-align: center;
      width: 100%;
    }
  }
  > div {
    padding: 0 10px;
    border-right: 1px solid #FFF;
    display: flex;
    align-items: center;
    i {
      color: #FFFFFF;
      margin-right: 5px;
      font-size: 12px;
    }
    > span {
      height: 38px;
      line-height: 38px;
      display: block;
      cursor: pointer;
      user-select: none;
      color: #FFFFFF;
      width: auto;
      padding-left: 15px;
      padding-right: 10px;
      font-size: 14px;
      border-radius: 5px;
    }
  }
}