@use "sass:math";

@import "./node_modules/bootstrap/scss/_functions.scss"; 
@import "./node_modules/bootstrap/scss/_variables.scss"; 
@import "./node_modules/bootstrap/scss/_mixins.scss"; 
@import "./node_modules/bootstrap/scss/_grid.scss"; 
@import "./node_modules/bootstrap/scss/utilities/_display.scss"; 
@import "./node_modules/bootstrap/scss/utilities/_flex.scss"; 
@import "./node_modules/bootstrap/scss/utilities/_spacing.scss"; 
@import "./node_modules/bootstrap/scss/utilities/_position.scss"; 
@import "./node_modules/bootstrap/scss/utilities/_text.scss"; 


$google-fonts: 'https://fonts.googleapis.com/css2'
              + '?family=Quicksand:wght@300;400;500;600;700'
              // + '&family=Lato:wght@400;700'
              + '&family=Indie+Flower'
              + '&family=Mali:wght@400;700'
              + '&family=Lora:wght@400;700'
              + '&family=Dancing+Script:wght@400;700'
              + '&family=Marck+Script:wght@400'
              + '&family=Roboto+Mono:wght@400;700'
              + '&family=Roboto+Condensed:wght@300;400;700'
              + '&family=Roboto:wght@300;400;500;700;900&display=swap';
@import url($google-fonts);

@import "_normalize";

$public-path: "/../public";
$fa-font-path: "#{$public-path}/static/webfonts";


$max-width-phone: 576px;
$max-width-tablet: 768px;
$min-width-desktop: $max-width-tablet + 1;
$max-width-md: 992px;
$max-width-lg: 1200px;

$thin: 300;
$light: 300;
$regular: 400;
$medium: 500;
$semibold: 600;
$bold: 700;

$white: #FFFFFF;
$black: #1A1A1A;
$dark: #1A1A1A;
$gray: #515151;
$red: #E38DB2;
//$green: #6BE36F;
$green: #188038;
$blue: #7799e3; // #a5d8ff;
$midblue: rgba($blue, 0.2);
$lightblue: rgba(165, 216, 255, 0.05);
$bgGray: #fafafa;

$baseFontSize: 18px;
$editorMaxWidth: 794px;
/*
A4
width: 794px;
height: 1123px;
*/

@import "_icons";

@import "_base";

header {
  width: 100%;
}
body {
  font-family: 'Quicksand', sans-serif;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.main {
  text-align: center;
  font-family: 'Quicksand', sans-serif;
}
footer {
  width: 100%;
  //height: 30px;
}
.branding {
  font-weight: $regular;
  letter-spacing: 0.03em;
  word-spacing: -0.2em;
  text-decoration: underline !important;
  opacity: 0.9;
  margin: 0px;
  user-select: none;
  .beta-flag {
    display: inline-block;
    padding: 6px;
    font-size: 0.5em;
    position: absolute;
    margin-top: 0;
    margin-left: 0.5em;
    border: 1px solid $blue;
    line-height: 0.5em;
    border-radius: 4px;
    color: $blue;
    user-select: none;
  }
}
.loading-container {
  width: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
  > div {
    margin: 50px auto;
  }
}
.loading {
  display: flex;
  .ripple {
    width: 40px;
    height: 40px;
  }
  span {
    font-size: 18px;
    line-height: 40px;
    padding-left: 10px;
  }
}

@keyframes fadeIn {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

@import "_text-style";
@import "_notification";
@import "_flex_slider";
@import "_ripple";
@import "_dropdown";
@import "_dropdown_button";
@import "_help_bubble";
@import "_alert";
@import "_modal";
@import "_help_menu";
@import "_small_editor";
@import "_article";
@import "_listpage";
@import "_listpage_options";
@import "_listpage_playlist";
@import "_listpage_welcome";
@import "_listpage_documents";
@import "_listpage_new";
@import "_wizard";
@import "_toolbar";
@import "_meta_editor";
@import "_sidebar";
@import "_editor";
@import "_selected_rows_toolbar";
@import "_bars";
@import "_word_browser";
@import "_chord_editor";
@import "_start_page";
@import "_login_screen";
@import "_login_form";

@import "_print";