.editor {
  font-size: $baseFontSize;
  background-color: $bgGray;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
  height: 100vh;
  width: 100vw;
  max-width: 100vw;
  overflow: hidden;

  #scrollable {
    position: relative;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    overflow-x: hidden;
    height: 100vh;
  }
  
  .document-area {
    background: #FFFFFF;
    padding: 0 0 30px 0;
    margin: 20px auto 295px auto;
    max-width: $editorMaxWidth;
    text-align: center;
    box-shadow: rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
    font-family: 'Quicksand', sans-serif;
  }

  h1, .group-container {
    transition: opacity 500ms;
  }
  &.is-loading .document-area {
    h1, .contributers, .group-container {
      filter: blur(0.8px); 
      opacity: 0.8;
    }
  }
  
  > .loading {
      position: absolute;
      margin-left: 20px;
      margin-top: -20px;;
      background: #FFFFFF;
      z-index: 99;
      //display: block;
      //span {
      //  font-size: 1rem;
      //  font-weight: $light;
      //}
  }
  


  @media (max-width: $editorMaxWidth) { 
    .document-area {
      width: 100%;
      box-shadow: none;
    }
  }

  .title-wrapper {
    position: relative;
    padding-top: 30px;
    i {
      position: absolute;
      color: rgba($dark, 0.5);
      left: 60px;
      font-size: 20px;
      height: 1.6em;
      line-height: 1.6em;
      cursor: pointer;
      &:hover {
        color: $blue;
      }
    }
  }
  h1 {
    font-size: 1.6em;
    text-align: center;
    margin: 0 30px 0 30px;
  }

  .icon {
    height: 1em;
    width: 1em;
    margin: 0 0.1em;
    
    > i {
      color: $blue;
      //font-size: 0.8em;
      display: block;
    }
  }
  &.edit-active {
    .icon {
      cursor: pointer;
    }
  }
}

.editor {
  .contributers {
    margin-bottom: 0.7em;
    padding: 0 90px;
    .icon {
      line-height: 1.5rem;
      margin: 0;
      text-align:center;
      display: block;
      width: 100%;
      opacity: 0;
      transition: 800ms;
      transition-property: opacity;
      transition-timing-function: ease;

      > i {
        margin: auto;
        font-size: 0.6rem;
        line-height: 1.5rem;
      }
    }
    &:hover {
      .icon {
        opacity: 1;
      }
    }
    li {
      line-height: 0.9rem;
      height: 0.9rem;
      span {
        font-size: 0.7rem;
        font-weight: $thin;
      }
    }
  }
  &.edit-active {
    .contributers {
      cursor: pointer;
    }
  }
}

.editor {
  .group {
    padding: 0;
    > div {
      position: relative;
    }
    .group-inner {
      border-width: 1px;
      border-style: solid;
      border-color: rgba($blue, 0);
      border-radius: 6px;
      border-left-width: 3px;
      border-right-width: 3px;
      transition: border-color 500ms;
    }
    
    &.has-label {
      padding: 1em 0 0 0;
    }
  }
}

.editor {

  .group-tools {
    position: absolute;
    display: flex;
    justify-content: flex-start;
    justify-items: center;
    align-items: center;
    opacity: 0;
    height: 1.5em;
    background-color: #fbfcfe; // rgba($blue, 0.03);
    border: solid 1px rgba($blue, 0.2); // rgba($blue, 0.2);
    border-radius: 5px;
    transition-property: opacity;
    transition: 800ms;
    transition-timing-function: ease;

    &.gt-left {
      left: -50px;
      top: 50%;
    }
    &.gt-right {
      right: -50px;
      top: 50%;
      i {
        width: 100%;
      }
      .dropdown {
        div {
          top:  1.5em;
          text-align: left;
        }
      }
    }
    @media only screen and (max-device-width: $max-width-phone) {
      &.gt-right {
        right: 0;
        top: 0;
        right: 0;
        top: 0;
        opacity: 1;
        background-color: transparent;
        border: 0 none;
        .icon {
          height: 1em;
          width: 1em;
        }
      }
    }

    span {
      margin: 0 5px;
      display: block;
      height: 30px;
      width: 30px;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      i {
        color: $blue;
        font-size: 0.8em;
        display: block;
      }
    }
    .handle {
      cursor: grab;
      font-size: 24px;
    }
    &.active {
      opacity: 1;
    }
  }

  

  .groupcheckbox {
    position: absolute;
    z-index: 5;
    opacity: 0;

    //transition-property: opacity;
    //transition: 800ms;
    //transition-timing-function: ease;

    //left: calc(62px - 1em);
    margin-left: calc(1.5rem + 11px);
    top: 0;
    margin-top: 0; // -1em;
    
    height: 1.5rem;
    display: flex;
    align-items: center;

  }

  .checkbox {
    box-sizing: border-box;
    border: 1px solid $blue;
    border-radius: 0.3em;
    cursor: pointer;
    height: 0.9em;
    width: 0.9em;
    text-align: center;
    i {
      display: none;
      font-size: 0.5em;
      color: $blue;
      line-height: 0.9rem;
    }
    &.multiple {
      position: relative;
      &::before {
        content: "";
        position: absolute;
        top:0;
        bottom: 0;
        left: 0;
        right: 0;
        height: 0.9em;
        width: 0.9em;
        border-top: 1px solid $blue;
        border-right: 1px solid $blue;
        border-radius: 0.3em;
        transform: translate(2px, -3px);
      }
    }
    &.checked i {
      display: block;
    }
  }


  .groupcheckbox:hover {
    opacity: 1;
  }

  &:not(.edit-active) {
    .group-tools {
      display: none;
    }
    .groupcheckbox {
      display: none;
    }
  }
  @media only screen and (max-device-width: $max-width-phone) {
    .groupcheckbox {
      display: none;
    }
  }
}
 

.editor {
  .grouplabel {
      position: relative;
      //position: absolute;
      //transform: translateY(-1rem);
      opacity: 0;
      transition: 800ms;
      transition-property: opacity;
      transition-timing-function: ease;
      width: 100%;
      text-align: center;
      height: 1.5rem;
      &.active {
        opacity: 1;
        //position: relative;
        //transform: translateY(0);
      }
      h2 {
        line-height: 1.5rem;
        font-size: 0.7rem;
        padding: 0;
        margin: 0;
        width: 100%;
        font-weight: $light;
      }
      .icon {
        line-height: 1.5rem;
        margin: 0;
        > i {
          font-size: 0.6rem;
          line-height: 1.5rem;
        }
      }
  }
}

.editor {
  .rowtools {
    position: absolute;
    display: flex;
    align-items: center;
    opacity: 0;
    height: 100%;
    z-index: 2;
    &.rtl { 
      left: 0;
      justify-content: flex-start;
    }
    @media only screen and (max-device-width: $max-width-tablet) {
      &.rtl { 
        display: none;
      }
    }
    &.rtr { 
      right: 0;
      justify-content: flex-end;
    }
    .dropdown {
      > i {
        font-size: 14px;
      }
    }

    .handle {
      margin: 0 5px;
      display: block;
      height: 1.5em;
      width: 1.5rem;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      i {
        color: $blue;
        font-size: 0.8em;
        display: block;
      }
    }
    .handle {
      cursor: grab;
    }
    .checkbox {
      box-sizing: border-box;
      border: 1px solid $blue;
      border-radius: 0.3em;
      cursor: pointer;
      height: 0.9em;
      width: 0.9em;
      text-align: center;
      i {
        display: none;
        font-size: 0.5em;
        color: $blue;
        line-height: 0.9rem;
      }
      &.checked i {
        display: block;
      }
    }

    .swipeout {
      width: 0;
      overflow: hidden;
      transition: width 200ms;
      i {
        font-size: 14px;
      }
    }
    @media only screen and (min-device-width: $min-width-desktop) {
      &:hover .swipeout {
        width: 1em;
      }
    }
  }

  .row.lyrics-readonly {
    .rowtools.rtl { 
      display: none;
    }
  }
}

.editor {
  .row {
    display: block;
    line-height: 1.25em;
    padding: 0px 0;
    text-align: inherit;
    margin: 0;
    flex-wrap: initial;
  }

  .chords-row {
    padding: 0 25px;
    line-height: 1.5rem;
    height: 0;
    z-index: 1;
    display: flex;
    justify-content: center;
    overflow: hidden;
    transition: height 200ms;
    position: relative;
    white-space: nowrap;
    
    span {
      color: rgba(255, 255, 255, 0);
      background-color: #ffffff;
      display: inline-block;
      position: relative;
      user-select: none;
      transition: background-color 300ms;
      top: 3px;
    }
    @media only screen and (max-device-width: $max-width-phone) {
      span {
        font-size: 0.75rem;
      }
    }

    i.chord {
      display: block;
      color: #000000;
      position: absolute;
      z-index: 3;
      font-style: normal;
      font-family: 'Roboto Condensed', sans-serif;
      font-size: 0.85rem;
      line-height: 1.5rem;
      border: solid 1px transparent;
      i {
        font-style: normal;
        position: relative;
        top: -0.5em;
        font-size: 80%;
      }
      
    }
    @media only screen and (max-device-width: $max-width-phone) {
      i.chord {
        font-size: 0.6rem;
      }
    }
  }
  .row-inner {
    position: relative;
    line-height: 1.5em;
    height: auto;
    margin-top: -1px;
    margin-bottom: -1px;
    margin-left: -3px;
    margin-right: -3px;
    display: flex;
    justify-content: center;
    border: 1px solid transparent;
    &.lyrics {
      padding: 0 25px; // Desktop
      font-size: 1rem;
    }
    &.chordbars {
      padding: 0 90px; // Desktop
    }
    @media only screen and (max-device-width: $max-width-tablet) {
      &.chordbars {
        padding: 0 40px;
      }
    }
    @media only screen and (max-device-width: $max-width-phone) {
      &.lyrics {
        font-size: 0.75rem;
      }
      &.chordbars {
        padding: 0 20px;
      }
    }

    div.lyrics-outer {
      width:100%;
      position: relative;
      > span.placeholder {
        position: absolute;
        z-index: 1;
        font-size: 0.8rem;
        opacity: 0.5;
        left: 0;
        right: 0;
        text-align: center;
      }
      .rhyme-tag {
        color: rgba($dark, 0.5);
        position: absolute;
        transform: translateY(-100%);
        margin-top: -2px;
        font-size: 11px;
        line-height: 24px;
        height: 24px;
        display: inline-block;

        border: 1px solid transparent;
        border-radius: 5px;
        cursor: pointer;
        user-select: none;
        padding: 0 10px;
        font-family: 'Roboto', sans-serif;
        font-weight: 400;

        animation-name: fadeIn;
        animation-iteration-count: 1;
        animation-duration: 100ms;

        i {
          font-size: 16px;
          margin-right: 5px;
        }
        //&:hover {
        //  border: 1px solid rgba($blue, 0.2);
        //}
        span {
          //padding-left: 5px;
          font-size: 11px;
          font-family: 'Roboto', sans-serif;
          font-weight: 300;
          color: rgba($dark, 0.5);
          code {
            color: rgba($dark, 0.5);
            font-size: 11px;
            //padding: 0 5px;
            //border: 1px solid rgba($blue, 0.2);
            border-radius: 5px;
            font-family: 'Roboto', sans-serif;
            font-weight: 300;
            display: inline-block;
            padding-right: 2px;
            padding-left: 2px;
          }
        }
        /*
        &:before {
          color: rgba($dark, 0.5);
          font-family: 'Font Awesome 5 Free';
          font-weight: 900;
          content: "\f002";
          margin-right: 5px;
          font-size: 11px;
        }
        */
      }
    }
    
    div[contenteditable] {
      width: 100%;
      white-space: nowrap;
      overflow: hidden;
      z-index: 10;
      em {
        //background-color: rgba($blue, 0.2);
        border-bottom: solid 2px rgba($blue, 0.5);
        font-style: normal;
      }
      span.rhyme-base {
        border-bottom: solid 2px rgba($green, 0.5);
        //border-radius: 5px;
      }
    }

    &.ref:hover {
      .lyrics-outer {
        div {
          opacity: 0.6;
          cursor: default;
          //font-style: italic;
        }
      }
    }
  }

  
}

.editor {
  .creation-row {
    margin-top: 10px;
    display: flex;
    justify-content: center;
    button {
      margin: 0px 5px;
    }
  }
  .document-area .creation-row {
    opacity: 0;
    transition: opacity 800ms;
  }
  &.edit-active .document-area:hover .creation-row { // :not(.lyrics-readonly)
    opacity: 1;
  }
  &:not(.edit-active) .document-area .creation-row {
    display: none;
  }
}


.editor .dropdown, #dropdown-root {
  > div {
    > ul {
      > li {
        i {
          color: $blue;
          line-height: 18px;
        }
      }
    }
  }
}


.editor.align-left {
  h1 {
    text-align: left;
    padding-right: 60px;
    padding-left: 60px;
  }
  .document-area {
    text-align: left;
  }
  .chords-row {
    justify-content: left;
    padding: 0 90px;
  }
  .grouplabel {
    text-align: left;
    padding: 0 90px;
  }
  .row-inner.lyrics {
    padding: 0 90px;
  }
  @media only screen and (max-device-width: $max-width-phone) {
    h1 {
      padding: 0 0px;
    }
    .contributers {
      padding: 0 25px;
    }
    .chords-row {
      padding: 0 25px;
    }
    .grouplabel {
      padding: 0 25px;
    }
    .row-inner.lyrics {
      padding: 0 25px;
    }
  }
}

.no-touch {
  .editor.edit-active { // :not(.lyrics-readonly)
    .group:hover {
      .group-tools, .grouplabel {
        opacity: 1;
      }
    }
    .group:hover {
      .groupcheckbox {
        opacity: 1;
      }
    }
  }
}

.editor.edit-active { // :not(.lyrics-readonly)
  .group.focused {
    .group-tools, .grouplabel {
      opacity: 1;
    }
  }
  .group {
    &.focused > .group-inner {
      border-left-width: 3px;
      border-left-color: $blue;
      border-right-width: 3px;
      border-right-color: $blue;
      background: linear-gradient(90deg, rgba($blue, 0.03), #FFF 20%, #FFF 80%, rgba($blue, 0.03) 100%);
    }
  }
}


.editor.lyrics-readonly {
  .group-tools {
    display: none;
  }
}


.no-touch {
  .editor.edit-active {
    .group {
      > div:hover .group-inner {
        border-left-width: 3px;
        border-left-color: $blue;
        border-right-width: 3px;
        border-right-color: $blue;
        background: linear-gradient(90deg, rgba($blue, 0.03), #FFF 20%, #FFF 80%, rgba($blue, 0.03) 100%);
      }
    }
  }
}

.editor.edit-active {
  .chords-row {
    span {
      &[data-chord=""]:hover {

        cursor: pointer;

        &:before {
          left: 0;
          top: 0;
          bottom: 0;
          width: 0.5em;
          z-index: 5;
          position: absolute;
          content: "";
          background-color: rgba($blue, 0.01);
          border: solid 1px rgba($blue, 0.2);
        }
      }
      &[data-chord=""].dragover {
        &:before {
          left: 0;
          top: 0;
          bottom: 0;
          width: 0.5em;
          z-index: 5;
          position: absolute;
          content: "";
          background-color: rgba($blue, 0.01);
          border: solid 1px rgba($blue, 0.2);
        }
      }
      /*
      &.editing {
        &:before {
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
          width:auto;
          z-index: 5;
          position: absolute;
          content: "";
          border-bottom: solid 1px $blue;
        }
      }
      */
      i.chord:hover {
        cursor: pointer;
        background-color: $blue;
        color: #FFFFFF;
        border-radius: 2px;
      }
    }
  }
}

.editor.edit-active {
  .row-inner.focused {
    background-color: rgba($blue, 0.01);
    border: solid 1px rgba($blue, 0.1);
    .rowtools {
      opacity: 1;
    }
  }
  .group.focused .row:first-child .row-inner {
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
  }
  .group.focused .row:last-child .row-inner{
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
  }
}
.no-touch {
  .editor.edit-active {
    .row-inner:hover {
      background-color: rgba($blue, 0.01);
      border: solid 1px rgba($blue, 0.1);
      .rowtools {
        opacity: 1;
      }
    }
    .row-inner.ref:hover {
      background-color: rgba($red, 0.01);
      border: solid 1px rgba($red, 0.1);
    }
    .group:hover .row:first-child .row-inner {
      border-top-left-radius: 6px;
      border-top-right-radius: 6px;
    }
    .group:hover .row:last-child  .row-inner{
      border-bottom-left-radius: 6px;
      border-bottom-right-radius: 6px;
    }
  }
}

.editor.chords-active {
  .document-area .row .chords-row {
    height: 1.25em;
    overflow: visible;
  }
}

.editor .chords-row > span {
  background-color: transparent;
}

.musixmatch-copyright {
  text-align: center;
  p {
    font-size: 13px;
    color: $dark;
    opacity: 0.6;
    font-family: 'Roboto Condensed', sans-serif;
    font-weight: 300;
  }
  img {
    max-height: 25px;
    width: auto;
    filter: saturate(0);
  }
}

// animation: opacityhiglight 800ms ease-out;
@keyframes opacityhiglight {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.8;
  }
  100% {
    opacity: 0;
  }
}

/*
.editor.chords-active .chords-row > span {
  animation: chordshighlight 1200ms ease-out;
}
@keyframes chordshighlight {
  0% {
    background-color: transparent;
  }
  50% {
    background-color: rgba($blue, 0.2);
  }
  100% {
    background-color: transparent;
  }
}

@for $spani from 1 through 100 {
  .editor .chords-row > span:nth-child({$spani}) {
    animation-delay: {$spani*30}ms;
  }
}
*/