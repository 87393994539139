$sidebar-width: 300px;

.sb-container {
  position: absolute;
  overflow: visible;
  // height: calc(100vh - 61px);
  width: 0;
  .sb-opener {
    background-color: #FFF;
    color: #aaaaaa;
    position: absolute;
    z-index: 15;
    top: 20px;
    //margin-top: 20px;
    width: 30px;
    text-align: center;
    line-height: 20px;
    box-shadow: rgba(48, 62, 73, 0.15) -1px 0px 3px 1px;
    cursor: pointer;
    transition: right 200ms ease, left 200ms ease, top 50ms ease;
    span {
      display: block;
      padding: 5px 0;
    }
    i {
      width: 20px;
      height: 20px;
      border-radius: 0.3em;
      color: #aaaaaa;
    }
    &:hover {
      span {
        background-color: rgba($blue, 0.2);
      }
    }
  }
  .sidebar {
    position: absolute;
    z-index: 20;
    //margin-top: 20px;
    top: 20px;
    //transition: top 1000ms ease;
    height: calc(100vh - 90px - 10px);
    width: $sidebar-width;
    background-color: #fff;
    box-shadow: rgba(60, 64, 67, 0.15) -1px 0px 3px 1px;
    text-align: left;
    font-size: 15px;
    transition: right 200ms ease 200ms, left 200ms ease 200ms, top 50ms ease, height 50ms ease;
    display: flex;
    flex-direction: column;
    .sb-head {
      line-height: 30px;
      padding: 5px 20px;
      span {
        cursor: pointer;
        color: #aaaaaa;
      }
    }
  }
}

/*
.scrolled-below-toolbar {
  .sb-container {
    height: calc(100vh);
    .sb-opener, .sidebar {
      top: -40px;
    }
  }
}
*/


.sb-container {
  &.sb-left {
    left: 0;
    .sb-opener {
      left: 0;
      &.hidden {
        left: -50px;
      }
    }
    .sidebar {
      left: -$sidebar-width;
      &.open {
        left: 0px;
      }
      .sb-head {
        text-align: right;
      }
    }
  }

  &.sb-right {
    right: 0;
    @media only screen and (max-device-width: $max-width-phone) {
      display: none;
    }
    .sb-opener {
      right: 0;
      &.hidden {
        right: -50px;
      }
    }
    .sidebar {
      right: -$sidebar-width;
      &.open {
        right: 0px;
      }
      .sb-head {
        text-align: left;
      }
    }
  }
}



.sb-body {
  flex-grow: 1;
  padding: 0 15px 15px 15px;
  display: flex;
  align-items: stretch;
  > div {
    flex-grow: 1;
    width: 100%;
  }
  .sb-tools {
    height: 20px;
    display: flex;
    span {
      width: 20px;
      height: 20px;
      line-height: 20px;
      font-size: 13px;
      cursor: pointer;
      user-select: none;
      text-align: center;
      color: $dark;
      border-radius: 0.3em;
      margin-right: 2px;
      background: $lightblue;
      i {
        font-size: 11px;
        color: #aaaaaa;
        
      }
      &:hover {
        background-color: $blue;
        i {
          color: #FFFFFF;
        }
      }
    }
  }

  .snippets-about {
    p {
      font-size: 12px;
      line-height: 16px;
      margin: 0;
      color: $black;
      padding: 5px;
    }
  }

  .snippets {
    > div {
      margin-bottom: 10px;
      background-color: rgba($blue, 0.05);
      padding: 5px;
      position: relative;
    }
    .dropdown {
      position: absolute;
      right: 0;
      top: 0;
      z-index: 50;
      label i {
        font-size: 11px;
        opacity: 0.5;
      }
      div {
        z-index: 60;
      }
    }
    p {
      font-size: 12px;
      line-height: 16px;
      margin: 0;
      color: $black;
      cursor: pointer;
      padding: 5px;
    }
  }

  .snippet-input {
    //background-color: rgba($blue, 0.05);
    position: relative;
    textarea {
      padding: 5px;
      border: 0.5px solid rgba($blue, 0.2);
      background: #FFF;
      font-size: 12px;
      line-height: 16px;
      min-height: 16px;
      width: 100%;
      overflow: hidden;
      resize: none;
      border-radius: 5px;
    }
    button {
      height: 20px;
      font-size: 11px;
      line-height: 1;
    }
  }

  .snippet-new {
    margin-bottom: 10px;
    border: 0 none;
    background-color: transparent;
    font-size: 12px;
    text-align: center;
    width: 100%;
    height: 16px;
    line-height: 16px;
    padding: 10px;
    cursor: pointer;
    span {
      position: relative;
      display: inline-block;
      padding-left: 15px;
    }
    > span:before {
      content: "";
        display: inline-block;
        position: absolute;
        top: 3px;
        left: 0px;
        width: 14px;
        height: 14px;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' viewBox='0 0 100 125' version='1.1' x='0px' y='0px'%3E%3Ctitle%3E3.5%3C/title%3E%3Cdesc%3ECreated with Sketch.%3C/desc%3E%3Cg stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg fill='%23AAAAAA'%3E%3Cpath d='M33,49.5 L33,49.5 L33,49.5 C33,50.8807119 34.1192881,52 35.5,52 L46,52 L46,52 C46.5522847,52 47,52.4477153 47,53 L47,63.5 L47,63.5 C47,64.8807119 48.1192881,66 49.5,66 L49.5,66 L49.5,66 C50.8807119,66 52,64.8807119 52,63.5 L52,53 L52,53 C52,52.4477153 52.4477153,52 53,52 L63.5,52 L63.5,52 C64.8807119,52 66,50.8807119 66,49.5 L66,49.5 L66,49.5 C66,48.1192881 64.8807119,47 63.5,47 L53,47 L53,47 C52.4477153,47 52,46.5522847 52,46 L52,35.5 L52,35.5 C52,34.1192881 50.8807119,33 49.5,33 L49.5,33 L49.5,33 C48.1192881,33 47,34.1192881 47,35.5 L47,46 L47,46 C47,46.5522847 46.5522847,47 46,47 L35.5,47 L35.5,47 C34.1192881,47 33,48.1192881 33,49.5 Z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
        background-position: center;
        background-size: 19px;
        background-repeat: no-repeat;
    }
  }

  h3 {
    font-weight: 400;
    font-family: 'Roboto', sans-serif;
    text-transform: uppercase;
  }
  .song-list {
    list-style-type: none;
    padding: 0;
    li {
      line-height: 1.5em;
      &.active {
        a {
          font-weight: $semibold;
        }
      }
      &.deleted {
        font-style: italic;
        opacity: 0.8;
      }
    }
  }
}