
.start-page {
  overflow: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  .bg-fill {
    background-color: #F8F9FA; // rgba($blue, 0.03);
  }
  .bg-gradient {
    background: linear-gradient(180deg, rgba($blue, 0.03), #FFF 120px, #FFF 100%);
  }
  @media (min-width: 576px) {
    .container.mobile-full-width {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
  }
  

  .hero {
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    background-color: #000000;
    background-image: url("#{$public-path}/static/images/front-bg.jpg");
    background-size: auto 100%;
    background-repeat: no-repeat;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    @media (max-height: 900px) and (min-width: $max-width-phone) {
      background-size: auto 150%;
    }
    @media (max-height: 550px) {
      background-size: auto 200%;
    }
    @media (max-height: 380px) {
      background-size: auto 300%;
    }

    p {
      margin: 0 0 0.2em 0;
      //font-size: 100%;
    }
    .hero-inner {
      text-align: left;
    }
    
    .hero-buttons {
      margin-top: 15px;
      button {
        display: inline-block;
        min-width: 150px;
        &:first-child {
          margin-right: 15px;
        }
      }
    }
    .hero-more {
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      > div {
        animation: bounce 2s infinite;
      }
      p {
        font-size: 14px;
        line-height: 1.5em;
        color: #FFFFFF
      }
      i {
        font-size: 12px;
        line-height: 1.5em;
        color: #FFFFFF
      }
    }
  }

  .text-section {
    padding-top: 80px;
    padding-bottom: 80px;
    min-height: 0px;
    align-items: center;
    .row > div {
      //min-height: 0px;
      display: flex;
      justify-content: center;
      //align-items: center;
    }
  }
  @media (min-width: 576px) {
    .text-section {
      //padding-top: 100px;
      //padding-bottom: 100px;
      min-height: 200px;
    }
  }

  video {
    width: 100%;
    height: auto;
  }

  .columns {
    padding-top: 80px;
    padding-bottom: 80px;
    position: relative;
    .right-stretched-column {
      position: static;
      border-top: rgba($blue, 0.2) solid 1px;
      border-right: rgba($blue, 0.2) solid 1px;
      border-bottom: rgba($blue, 0.2) solid 1px;
      border-left: rgba($blue, 0.2) solid 1px;
    }
    .row {
      position: relative;
      > div {
        min-height: calc(50vw * 0.5625);
        display: flex;
        align-items: center;
        text-align: center;
        min-height: 200px;
        padding-bottom: 80px;
      }
    }
    @media (min-width: 768px) {
      .right-stretched-column {
        left: 50%;
        top: 80px;
        position: absolute;
        width: calc(50vw);
        height: calc(50vw * 0.5625);
        video {
          border-top: rgba($blue, 0.2) solid 1px;
          border-right: 0 none;
          border-bottom: rgba($blue, 0.2) solid 1px;
          border-left: rgba($blue, 0.2) solid 1px;
        }
      }
      .row > div {
        min-height: calc(50vw * 0.5625);
        display: flex;
        align-items: center;
        text-align: left;
        padding-bottom: 0;
      }
    }
  }

  .screenshot-gallery {
    padding-top: 80px;
    padding-bottom: 80px;
    img {
      width: 100%;
      height: auto;
    }
  }
  //@media (min-width: 576px) {
  //  .screenshot-gallery {
      //padding-top: 100px;
      //padding-bottom: 100px;
  //  }
  //}

  footer {
    display: block;
    border-top: solid 1px rgba($blue, 0.2);
    
    .row {
      padding-top: 80px;
      padding-bottom: 80px;
    }

    .service-branding {
      margin-top: 50px;
      display: flex;
      justify-content: center;
      img {
        width: auto;
        height: 50px;
        margin: 0 15px;
      }
    }
  }
}

@keyframes bounce {
  0%, 40%, 70%, 85%, 100% {
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  60% {
    transform: translateY(-10px);
  }
  80% {
    transform: translateY(-5px);
  }
}