.browser-page {
  .doc-list-wrapper {
    .list-title {
      display: flex;
      padding: 0 0 0 52px;
      margin: 0 0px 20px 0px;
      > div {
        height: 38px;
        text-align: center;
        line-height: 38px;
        margin: 0 5px;
        > span {
          display: block;
          border: 0 none;
          border-radius: 50%;
          height: 38px;
          width: 38px;
          display: block;
          cursor: pointer;
          user-select: none;
          border: 1px solid transparent;
          &:hover {
            border: 1px solid rgba($blue, 0.2);
          }
        }
      }
      h1 {
        font-size: 1.6rem;
        text-align: left;
        font-weight: 600;
        height: 38px;
        line-height: 38px;
        margin: 0;
        padding: 0;
      }
    }
    .list-buttons {
      padding: 30px 0 0 100px;
    }
  }
}