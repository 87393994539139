.notification {
  box-shadow: rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
  //border: solid 1px $blue;
  z-index: 1000;
  background: #FFF;
  display: block;
  position: absolute;
  padding: 15px 20px;
  left: 20px;
  margin-top: 20px;
  opacity: 0;
  transition-timing-function: ease;
  transition-property: opacity;
  transition-duration: 1500ms;
  transition-delay: 1000ms;
  max-width: 350px;
  text-align: left;
  &.slow {
    transition-delay: 8000ms;
  }
  &.is-visible {
    opacity: 1;
    transition: ease opacity 200ms;
  }

  .notif-close {
    right: 10px;
    top: 10px;
    position: absolute;
    border-radius: 2px;
    width: 20px;
    height: 20px;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    cursor: pointer;
    color: #aaaaaa;
    &:hover {
      background-color: rgba($blue, 0.2);
      color: #000000;
    }
  }

  p {
    line-height: 22px;
    font-size: 14px;
    padding: 0;
    margin: 0;
    font-family: 'Roboto', sans-serif;
    font-weight: 300;
    div img {
      margin-top: 15px;
      width: 75%;
      height: auto;
    }
  }
}